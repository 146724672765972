// dependencies
import React from 'react';
import { createUseStyles } from 'react-jss';

interface LayoutStyleProps {
	maxWidth?: string;
}

const useStyles = createUseStyles({
	container: {
		maxWidth: ({ maxWidth }: LayoutStyleProps) => maxWidth ? maxWidth : '1200px',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		margin: 'auto',
		'@media (min-width: 794px)': {
			padding: '14px 0',
		}
	}
})

interface LayoutProps {
	maxWidth?: string;
	children: JSX.Element;
}

const Layout: React.FC<LayoutProps> = ({ children, maxWidth }) => {
	const classes = useStyles({ maxWidth })
	return (
		<>
			<main>
				<div className={classes.container}>{children}</div>
			</main>
		</>
	)
}

export default Layout;

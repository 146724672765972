// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, Auth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6SLrn0Bn_FyWtY2Z3Or8kcvDnsjMElj4",
  authDomain: "wishlist-aff4e.firebaseapp.com",
  projectId: "wishlist-aff4e",
  storageBucket: "wishlist-aff4e.appspot.com",
  messagingSenderId: "946551394718",
  appId: "1:946551394718:web:478b6e6d51c429e99f468f",
  measurementId: "G-22EQGCRN1Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const auth: Auth = getAuth(app);
export const functions = getFunctions(app);

export const getDetails = httpsCallable(functions, "scrapeData");
export const fetchUrlDetails = (
  url
) => {
  return getDetails({ url })
};
export const provider = new GoogleAuthProvider();

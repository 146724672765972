import React from 'react';
import { createUseStyles } from 'react-jss';
import Header from './Header';
import Footer from './Footer';

interface Props {
    children: React.ReactNode;
    name: string;
    image?: string;
    numberOfItems: number;
    title: string;
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '900px',
        borderRadius: '20px',
        overflow: 'hidden',
        backgroundColor: 'white',
        '@media (max-width: 927px)': {
            borderRadius: 0,
        },
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
    },
});

const Container: React.FC<Props> = ({ children, name, image, title, numberOfItems }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Header
                name={name}
                image={image}
                title={title}
                numberOfItems={numberOfItems}
            />
            <div className={classes.content}>{children}</div>
            <Footer />
        </div>
    );
};

export default Container;

import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    text: ({ fontSize, fontWeight, fontStyle, marginTop, marginRight, marginBottom, marginLeft, fontColor, isLink }: TextStyleProps) => ({
        fontSize: fontSize || 'inherit',
        fontWeight: fontWeight || 'normal',
        fontStyle: fontStyle || 'normal',
        marginTop: marginTop || 0,
        marginRight: marginRight || 0,
        marginBottom: marginBottom || 0,
        marginLeft: marginLeft || 0,
        color: fontColor || 'inherit',
        // enable underline and pointer cursor if isLink is true and on hover
        '&:hover': {
            textDecoration: isLink ? 'underline' : 'none',
            cursor: isLink ? 'pointer' : 'inherit',
        },
    }),
});

interface TextStyleProps {
    fontSize?: number | string;
    fontWeight?: number | string;
    fontStyle?: string;
    marginTop?: number | string;
    marginRight?: number | string;
    marginBottom?: number | string;
    marginLeft?: number | string;
    fontColor?: string;
    isLink?: boolean;
}

interface TextProps extends TextStyleProps {
    onClick?: () => void;
    children?: React.ReactNode;
}

const Text: React.FC<TextProps> = ({
    children,
    fontSize,
    fontWeight,
    fontStyle,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    fontColor,
    isLink = false,
    onClick,
}) => {
    // destructuring props
    const classes = useStyles({ fontSize, fontWeight, fontStyle, marginTop, marginRight, marginBottom, marginLeft, fontColor, isLink });

    return (
        <p className={classes.text} onClick={onClick} >
            {children}
        </p>
    );
}

export default Text;

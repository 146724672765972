import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";
// resources
import editIcon from '../resources/editIcon.png'
import deleteIcon from '../resources/deleteIcon.png'


export const SaveStates = {
	default: "DEFAULT",
	saved: "ITEM SAVED SUCCESSFULLY",
	error: "ERROR WHILE SAVING DATA",
}


// create interface for dropdown menu options
export interface DropdownMenuOption {
	label: string;
	icon: string;
}
export const defaultDropdownOptions: DropdownMenuOption[] = [
	{
		label: 'Edit',
		icon: editIcon,
	},
	{
		label: 'Delete',
		icon: deleteIcon,
	},
];

export const dropdownOptionsWithMove: DropdownMenuOption[] = [
	...defaultDropdownOptions,
	{
		label: 'Move',
		icon: editIcon,
	},
];

export enum AnalyticsEventNames {
	BUTTON_CLICK = "button_click",
}


export interface AnalyticsEventParams {
	buttonId: string;
	pageName?: string;
	pageLocation?: string;
	componentName?: string;
}

export const logAnalyticsEvent = (eventName: AnalyticsEventNames, eventParams: AnalyticsEventParams) => {
	// TODO: remove this console.log
	console.log("Logging analytics event: ", eventName, eventParams)
	logEvent(analytics, eventName, eventParams);
}
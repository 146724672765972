import React from 'react';
import { createUseStyles } from 'react-jss';
import chromeLogo from '../resources/chromeLogo.png';
import { constants } from '../Services/constants';

// createUseStyles that takes a boolean as a parameter

type StyleProps = {
    isHorizontal: boolean;
};


const useStyles = createUseStyles({
    container: {
        backgroundColor: 'black',
        paddingTop: '45px',
        borderRadius: '20px',
        bottom : '10%',
        // background: 'radial-gradient(white, black)',
        padding: ({ isHorizontal }: StyleProps) => isHorizontal ? '15px 15px 15px 15px' : '15px',
        position: 'relative',
        textAlign: 'center',
    },
    logo: {
        position: 'absolute',
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '80px',
        height: '80px',
    },
    logoHorizontal: {
        position: 'absolute',
        // middle of the container
        top: '0',
        transform: 'translateY(55%)',
        right: '4%',
    },
    heading: {
        color: 'rgba(255, 255, 255, 0.8);',
        fontSize: '1.7rem',
        
    },
    description: {
        color: 'rgba(255, 255, 255, 0.8);',
    },
    button: {
        backgroundColor: 'rgb(180 80 248)',
        borderRadius: '10px',
        color: 'black',
        padding: '10px',
        width: '70%',
        fontWeight: 'bold',
        fontSize:'14px',
        cursor: 'pointer',
        border:'none',
    },
});

interface BannerChromeExtensionProps {
    heading?: string;
    description?: string;
    isHorizontal?: boolean;
    logEvent: (buttonId: string, componentName: string) => void;
}

const BannerChromeExtension: React.FC<BannerChromeExtensionProps> = ({ heading = "Grab the Extension!", description = "Make list creation easier and fun.", isHorizontal = false, logEvent }) => {
    const classes = useStyles({ isHorizontal });

    const handleButtonClick = () => {
        // open a url on a new tab on any browser
        try {
            logEvent(constants.ANALYTICS_IDS.COMPONENTS.BANNER_CHROME_EXTENSION.DOWNLOAD_BUTTON_ID, constants.ANALYTICS_IDS.COMPONENTS.BANNER_CHROME_EXTENSION.COMPONENT_NAME);
        } catch (error) {
            console.log("error logging download event from banner chrome extension", error);
        }
        window.open(constants.CHROME_EXTENSION_URL, '_blank');
    };

    return (
        <div className={classes.container}>
            {/* show image if isHorizontal */}
            {!isHorizontal && <img src={chromeLogo} alt="Google Chrome" className={classes.logo} />}
            {isHorizontal && <img src={chromeLogo} alt="Google Chrome" className={classes.logoHorizontal} />}
            <h1 className={classes.heading}>{heading}</h1>
            <p className={classes.description}>{description}</p>
            <button className={classes.button} onClick={handleButtonClick} >Download</button>
        </div>
    );
};

export default BannerChromeExtension;

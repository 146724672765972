import { createUseStyles } from "react-jss";
import yuppies from "../resources/yuppies.png";
// material ui
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import topick_logo from "../resources/topick_logo.png";
import login_text_login from "../resources/login_text_login.png";
import google_text from "../resources/google_text.png";
import login_rightImage_background from "../resources/login_rightImage_background.png";
import image_group_login from "../resources/image_group_login.png";

const useStyles = createUseStyles({
  container: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      width: '100%',
      height: '100vh',
      margin: 'auto',
      display: 'flex',
      maxWidth: '1200px',
      //maxHeight: '900px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      //position: 'absolute',
      width: '100%',
      height: '100vh',
    },
  },
  signUp: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      width: '40%',
      //maxHeight: '900px',
      height: '100vh',
      left: '0px',
      top: '0px',
      background: '#000000',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      position: 'relative',
      width: '100%',
      height: '100%',
      left: '0px',
      top: '0px',
      background: '#000000',
    },

  },
  imgWrapper: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      width: '197.61px',
      height: '80px',
      left: '7%',
      top: '7%',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      position: 'relative',
      width: '198px',
      height: '80px',
      left: '7%',
      top: '7%',
    },

  },
  topicklogo: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      width: '100%',
      height: '100%',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      width: '100%',
      height: '100%',
      // left:'16px',
      // top:'40px',
    },
  },
  welcome_text: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      width: '440.7px',
      height: '75px',
      left: '65px',
      top: '178px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '140%',
      textTransform: 'capitalize',
      color: '#AFAFB2',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      position: 'absolute',
      width: '197.61px',
      height: '80px',
      left: '50px',
      top: '64px',
    },
  },
  logindiv: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      maxWidth: '313px',
      maxHeight: '244px',
      left: '7%',
      top: '20%',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      position: 'relative',
      width: '300px',
      height: '200px',
      left: '7%',
      top: '20%',
    },
  },
  logindivimg: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      //position: 'relative',
      width: '100%',
      maxHeight: '100%',
      // left: '7%',
      // top: '20%',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      //position: 'absolute',
      width: '100%',
      height: '100%',
      left: '5%',
      position: 'relative',
    },
  },
  logindiv_text: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      // position: 'absolute',
      // width: '313px',
      // height: '244px',
      // left: '65.69px',
      // top: '244px',
      display: 'none',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      // position: 'absolute',
      // width: '300px',
      // height: '200px',
      // left: '50px',
      // top: '400px',
      // fontWeight: '400',
      // fontSize: '20px',
      // lineHeight: '26px',
      // textTransform: 'capitalize',
      // color: '#AFAFB2',
      // textAlign: 'center',
      display: 'none',
    },
  },
  googleLoginButton: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      minWidth: '200px',
      //maxWidth: '330px',
      width: '80%',
      height: '56px',
      left: '7%',
      top: '30%',
      background: '#FFFFFF',
      boxShadow: '0px 11px 24px -7px rgba(255, 255, 255, 0.25)',
      borderRadius: '12px',
      border: 'none',
      cursor: 'pointer',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      position: 'relative',
      width: '80%',
      height: '56px',
      left: '10%',
      //top: '30%',
      marginTop: '70%',
      background: '#FFFFFF',
      boxShadow: '0px 11px 24px -7px rgba(255, 255, 255, 0.25)',
      borderRadius: '10px',
      border: 'none',
    },
  },
  fullImage: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      height: '100vh',
      width: '60%',
      left: '0%',
      maxHeight: '900px',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      display: 'none',
    },
  },
  image1: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      zIndex: '1',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0%',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      display: 'none',
    },
  },
  image2: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      zIndex: '3',
      position: 'relative',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      left: '2%',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      display: 'none'
    },
  },
});


function SignUp_new({ handleLogin }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.signUp}>
          <div className={classes.imgWrapper}>
            <img className={classes.topicklogo} src={topick_logo} alt="topick logo" />
          </div>
          <div className={classes.logindiv}>
            <img className={classes.logindivimg} src={login_text_login} alt="topick login text" />
            <p className={classes.logindiv_text}>Make your online shopping experience effortless and enjoyable our platform and chrome extension</p>
          </div>
          <button className={classes.googleLoginButton} onClick={handleLogin}>
            <img src={google_text} alt="topick login text" />
          </button>
        </div>
        <div className={classes.fullImage}>
          <img className={classes.image1} src={login_rightImage_background} alt="image1" />
          <img className={classes.image2} src={image_group_login} alt="image2" />
        </div>
      </div>
    </>
  );
}

export default SignUp_new;

// style
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// react
import { useEffect } from 'react';
import { useContext } from 'react';

// internal
import { AppContext } from '../context';


export default function Loading() {
  const { userActions } = useContext(AppContext);

  useEffect(() => {
    const cancelTimeout = setTimeout(() => {
      userActions.onLogout();
    }, 5000)
    return () => {
      clearTimeout(cancelTimeout);
    }
  },[userActions])


  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

import React from 'react';
import { createUseStyles } from 'react-jss';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close'

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    header: string;
}

const useStyles = createUseStyles({
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 100,
    },
    modal: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '40px 20px',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
        zIndex: 101,
        width: '95%',
        maxWidth: '400px',
        minWidth: '280px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    },
});

const CustomModal: React.FC<ModalProps> = ({ isOpen, onClose, header, children }) => {
    const classes = useStyles();

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className={classes.backdrop}>
                <div className={classes.modal}>
                    <Typography variant="h6" component="h2">
                        {header}
                        <CloseIcon className={classes.closeButton} onClick={onClose} />
                    </Typography>
                    <div>{children}</div>
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;

import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    container: {
        height: ({ height = 'auto' }) => height,
        minHeight: ({ minHeight = 'auto' }) => minHeight,
        width: ({ width = '100%' }) => width,
        borderRadius: 10,
        //background: ({ backgroundColor = '#E0E3E2' }) => backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

function CenteredContainer({ height, minHeight, width, backgroundColor, children }) {
    const classes = useStyles({ height, minHeight, width, backgroundColor });
    return (
        <div className={classes.container}>
            {children}
        </div>
    );
}

export default CenteredContainer;

import React from 'react';
import { createUseStyles } from 'react-jss';

interface CardStyleProps {
    minHeight?: string;
    backgroundColor?: string;
    height?: string;
}

const useStyles = createUseStyles({
    card: {
        backgroundColor: ({ backgroundColor = '#E0E3E6' }: CardStyleProps) => backgroundColor,
        borderRadius: 10,
        padding: 20,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        minHeight: ({ minHeight = 'auto' }: CardStyleProps) => minHeight,
        height: ({ height = 'auto' }: CardStyleProps) => height,
    },
});

interface CardProps extends CardStyleProps {
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ minHeight, backgroundColor, children, height }) => {
    const classes = useStyles({ minHeight, backgroundColor, height });
    return (
        <div className={classes.card}>
            {children}
        </div>
    );
}

export default Card;

import React from 'react';
import { createUseStyles } from 'react-jss';
import NavbarNew from './NavbarNew';

const useStyles = createUseStyles({
    container: {
        height: '100%',
        width: '100%',
        '@media (min-width: 794px)': {
            display: 'flex',

        },
        // navigation
        '& > div:first-child': {
            '@media (min-width: 794px)': {
                marginRight: '3%',
            }

        },
        // rest of content
        '& > div:nth-child(2)': {
            width: '100%',
            '@media (max-width: 794px)': {
                padding: '20px 16px',
            }
        },
    },
    navigation: {
        '@media (min-width: 794px)': {
            marginRight: '3%',
        }
    },
    content: {
        width: '100%',
        '@media (max-width: 794px)': {
            padding: '20px 16px',
        }
    }
});

/**
 * TwoColumnLayout
 * Expected children:
 * - navigation
 * - content
 * @param children
 * @return {JSX.Element}
 * @example
 * <TwoColumnLayout>
 *  <div>navbar</div>
 *  <div>content</div>
 * </TwoColumnLayout>
 */
function TwoColumnLayout({ children }) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {children}
            {/* <div className={classes.navigation}>
                <NavbarNew />
            </div>
            <div className={classes.content}>{children}</div> */}
        </div>
    );
}

export default TwoColumnLayout;

import { useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import React, { useContext, useEffect, useState } from "react";

// internal
import { AppContext } from "../context";
// mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Alert, Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from '@mui/material/CircularProgress';
// images
import SaveItemForm from "../components/SaveItemForm";
//import { LocalMall } from "@mui/icons-material";
import Placeholder_image_create from "../resources/placeholder_image_create.png"
// firebase
import { serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { SaveStates } from "../Services/helpers";
import TwoColumnLayout from "../components/TwoColumnLayout";
import NavbarNew from "../components/NavbarNew";
import { AnalyticsEventNames, AnalyticsEventParams, logAnalyticsEvent } from '../Services/helpers'
import { constants } from '../Services/constants';
import { ItemObject, ItemUpdateObject } from "../App";


const useStyles = createUseStyles({
  body: {
    maxWidth: "1280px",
  },
  urlBoxComponent: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      minHeight: '160px',
      maxWidth: '909px',
      top: '1%',
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: '15px',
      marginBottom: '20px',
      justifyContent: "center",
    },
    '@media only screen and (max-width: 768px)': {
      width: "98%",
      minHeight: '190px',
      left: '1%',
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: '10px',
      marginBottom: '20px',
    },
  },
  urlBoxComponentBackButton: {
    //desktop
    '@media only screen and (min-width: 768px)': {

      left: '5%',
      top: '1%',
      position: 'relative',
      fontWeight: '400',
      letterSpacing: '0.05em',
      color: '#000000',
      fontStyle: 'normal',
      lineHeight: '20px',
      marginTop: '10px',
    },
    '@media only screen and (max-width: 768px)': {
      left: '3%',
      top: '0%',
      position: 'relative',
      fontWeight: '400',
      letterSpacing: '0.05em',
      color: '#000000',
      fontStyle: 'normal',
      lineHeight: '20px',
      marginTop: '10px',
    },
  },
  urlBox: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      top: '20px',
      position: 'relative',
      display: "flex",
      width: "90%",
      margin: "auto",
    },
    '@media only screen and (max-width: 768px)': {
      top: '20px',
      position: 'relative',
      //display: "flex",
      width: "90%",
      margin: "auto",
    },
  },
  fetchURLDetails: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      width: '22%',
      left: '1.5%',
      top: '15px',
      height: '40px',
      background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
      color: 'white',
      borderRadius: '15px',
      justifyContent: "center",
    },
    '@media only screen and (max-width: 768px)': {
      position: 'relative',
      width: '40%',
      left: '30%',
      top: '20px',
      height: '30px',
      background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
      color: 'white',
      borderRadius: '10px',
      justifyContent: "center",
      fontSize: '12px',
      fontWeight: '600',
    },
  },
  img: {
    maxHeight: "80%",
    maxWidth: "80%",
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  formbox: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      minHeight: '500px',
      background: 'white',
      borderRadius: '15px',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      position: 'relative',
      minHeight: '500px',
      width: '98%',
      height: '100%',
      left: '1%',
      background: 'white',
      borderRadius: '15px',
    }
  },
  content: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      display: "flex",
      position: 'relative',
      padding: '40px 20px 40px 20px',
      minHeight: '500px',

    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      //display: "flex",
      position: 'relative',
      padding: '25px 15px 25px 15px',
      minHeight: '500px',
      justifyContent: "center",
      alignItems: "center",
    },
  },
  imgBlock: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      width: '36%',
      left: '4%',
      position: 'relative',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: '#F9F9F9',
      borderRadius: '15px',
    },
    //mobile
    '@media only screen and (max-width: 768px)': {
      width: '94%',
      minHeight: '200px',
      left: '3%',
      position: 'relative',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: '#F9F9F9',
      borderRadius: '10px',
    }
  },
  form: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      width: "60%",
      left: '5%',
      position: 'relative',
      backgroundColor: 'white',
    },
    '@media only screen and (max-width: 768px)': {
      width: "96%",
      left: '2%',
      position: 'relative',
      backgroundColor: 'white',
      fontSize: '12px',
    },
  },
  saveAndCreate: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      width: '33%',
      left: '3.5%',
      //top: '8%',
      marginTop: '30px',
      position: 'relative',
      background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
      color: 'white',
      borderRadius: '15px',
      height: '40px',
    },
    '@media only screen and (max-width: 768px)': {
      width: "60%",
    },
  },
  urlTextBox: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      backgroundColor: 'white !important'
    },
    '@media only screen and (max-width: 768px)': {
    },
  },

});


const pageName = constants.ANALYTICS_IDS.PAGES.SAVE_ITEM.PAGE_NAME;
const pageLocation = constants.ANALYTICS_IDS.PAGES.SAVE_ITEM.PAGE_LOCATION;


const logButtonClickEvent = (buttonId: string, componentName: string) => {
  const eventParam: AnalyticsEventParams = {
    buttonId,
    componentName,
    pageName,
    pageLocation,
  };
  logAnalyticsEvent(AnalyticsEventNames.BUTTON_CLICK, eventParam);
}


interface AddProductProps {

}


const AddProduct: React.FC<AddProductProps> = () => {
  const classes = useStyles();
  const { handleSubmit, reset, control, setValue, register } = useForm();
  const { dbOperations, userInfo, fetchUrlDetails } = useContext(AppContext);
  const [url, setUrl] = useState("");
  const [isUrlFetchInProgress, setIsUrlFetchInProgress] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [saveState, setSaveState] = useState({});
  const navigate = useNavigate();

  const [itemToBeSaved, setItemToBeSaved] = useState<ItemObject>();
  const [isUrlParamParsed, setIsUrlParamParsed] = useState(false);

  //  listID is populated when user navigates to this page from one of the list pages
  const [listId, setListId] = useState<string | undefined>(undefined);
  // itemId is populated when user wants to edit an item from one of the list pages
  const [itemId, setItemId] = useState<string | undefined>(undefined);

  const onSubmit = async (data) => {
    console.log(data);
    if (Object.keys(userInfo).length === 0) {
      setSaveState(SaveStates.error);
      return;
    }

    const { url, listId: listIdFromFormUpdatedByUser, title, description, price } = data;
    // extract currency from price
    let currency = "";
    try {
      currency = price ? price.match(/[^0-9.]/g).join("") : "";
    } catch (error) {
      console.log("error in extracting currency from price")
      currency = "";
    }

    // the data doesn't contain image as image is not part of the form component, so it has to be added separately
    let itemDetails = {
      url,
      title,
      description,
      price,
      currency,
      img: itemToBeSaved?.img ? itemToBeSaved.img : "",
      isDone: false
    };

    let isSuccessful = false;
    // if itemId is present, it means user wants to update the item
    if (itemId) {
      isSuccessful = await dbOperations.updateItemDetailsInList(listIdFromFormUpdatedByUser, itemId, itemDetails as ItemUpdateObject);
    } else {
      
      isSuccessful = await dbOperations.addItemToList(listIdFromFormUpdatedByUser, itemDetails as ItemObject);
    }

    setSaveState(isSuccessful ? SaveStates.saved : SaveStates.error);
    navigate(`/list/${listIdFromFormUpdatedByUser}`);
  };

  // parsing url params
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { title, price, currency, description, url, img, listId, itemId } = params;
    // check if listId is present and set it
    if (listId) {
      setListId(listId);
    }
    // check if itemId is present and set it
    if (itemId) {
      setItemId(itemId);
    }
    if (title || price || currency || description || url || img) {
      setItemToBeSaved({ title, price, currency, description, url, img, isDone: false, updatedT: serverTimestamp(), createdT: serverTimestamp() });
    }
    setIsUrlParamParsed(true);
  }, []);

  // fetch list details for all lists
  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      userInfo.lists.forEach((listId) => {
        // if listId is not in the listDetails, fetch the details
        const listDetailsIndex = userInfo.listsDetails.findIndex(
          (list) => list.id === listId
        );
        if (listDetailsIndex === -1) {
          dbOperations.fetchListDetailsById(listId, true);
        }
      });
    }
  }, [userInfo]);

  // once the listsDetails is populated, set the default list value if the listId is not set
  useEffect(() => {
    if (userInfo.listsDetails.length > 0 && !listId) {
      setListId(listId ? listId : userInfo.listsDetails[0].id);
    }
  }, [userInfo.listsDetails]);
  

  // snack bar click handlers

  const handleClose = () => {
    setSaveState(SaveStates.default);
  };

  const handleUrlSearch = (e, url) => {
    e.preventDefault();
    setIsUrlFetchInProgress(true)
    fetchUrlDetails(url)
      .then((result: any) => {
        const { title, price, currency, description, img } = result.data;
        if (title || price || currency || description || img) {
          setItemToBeSaved({ title, price, currency, description, url, img, isDone: false, updatedT: serverTimestamp(), createdT: serverTimestamp() });
        }
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log("Error in function call");
        console.log(code);
        console.log(message);
        console.log(details);
      })
      .finally(() => setIsUrlFetchInProgress(false))

  };

  useEffect(() => {
    const stringIsAValidUrl = (s, protocols = ["http", "https"]) => {
      try {
        const url = new URL(s);
        return protocols
          ? url.protocol
            ? protocols.map((x) => `${x.toLowerCase()}:`).includes(url.protocol)
            : false
          : true;
      } catch (err) {
        return false;
      }
    };
    if (url === undefined || url === "" || stringIsAValidUrl(url)) {
      setIsValidUrl(true);
    } else {
      setIsValidUrl(false);
    }
  }, [url]);

  const handleBackButtonClick = () => {
    logButtonClickEvent("back_button_click", "add_product_page");
    if (!listId) {
      navigate(`/list`);
    } else {
      navigate(`/list/${listId}`);
    }
  }

  return (
    <>
      <Layout >
        <TwoColumnLayout>
          <NavbarNew
            logEvent={logButtonClickEvent}
          />

          <Box
            className={classes.body}
            sx={{
              width: "100%"
            }}
          >


            <div className={isUrlFetchInProgress ? classes.disabled : ""}>
              {/* url box */}
              <div className={classes.urlBoxComponent}>
                <Button className={classes.urlBoxComponentBackButton}
                  onClick={handleBackButtonClick}
                >
                  {"< Go to the list"}
                </Button>
               
                <div className={classes.urlBox}>

                  <TextField className={classes.urlTextBox}
                    id="outlined-basic"
                    label={isValidUrl ? "Add your URL to get started" : "Invalid URL"}
                    variant="filled"
                    fullWidth
                    sx={{ mr: 2 }}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    error={!isValidUrl}

                  />

                  {isUrlFetchInProgress ? <CircularProgress /> :
                    <Button onClick={(e) => { if (isValidUrl) { handleUrlSearch(e, url) } }} className={classes.fetchURLDetails}>Fetch</Button>
                  }
                </div>
              </div>

              {/* content */}
              {isUrlParamParsed &&
                <AddItemFormWithImage
                  itemToBeSaved={itemToBeSaved}
                  setItemToBeSaved={setItemToBeSaved}
                  control={control}
                  setValue={setValue}
                  register={register}
                  onSubmit={handleSubmit(onSubmit)}
                  defaultListValue={listId}
                />}
            </div>
          </Box>

          {/* notification  */}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={saveState === SaveStates.saved}
            onClose={handleClose}
            autoHideDuration={5000}
            // message="The item was saved to your default list. Feel free to edit and save again."
            key={SaveStates.saved}
          >
            <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
              The item was saved to your default list. Redirecting to your lists.
            </Alert>
          </Snackbar>

        </TwoColumnLayout>
      </Layout>
    </>
  );
}


export const AddItemFormWithImage = ({ itemToBeSaved, setItemToBeSaved, control, setValue, register, onSubmit, defaultListValue }) => {
  const classes = useStyles();

  return (
    <Box className={classes.formbox}
      component="form"
      onSubmit={onSubmit}
    >
      <div className={classes.content}>
        {/* img block */}
        <div className={classes.imgBlock}>
          {itemToBeSaved?.img ? (
            <>
              <img
                src={itemToBeSaved.img}
                className={classes.img}
                alt="the product added"
              />
              <Button
                sx={{ mt: 2, color: "#E80E6C", fontWeight: "bold" }}
                onClick={() =>
                  setItemToBeSaved({ ...itemToBeSaved, img: "" })
                }
              >
                Remove
              </Button>
            </>
          ) : (
            <img src={Placeholder_image_create} alt="image1" />
          )}
        </div>

        {/* form section  */}
        <div className={classes.form}>
          <SaveItemForm
            control={control}
            setValue={setValue}
            register={register}
            defaultListValue={defaultListValue}
            {...itemToBeSaved}
          />
        </div>
      </div>
    </Box>
  )
}

export default AddProduct;

import React, { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import HomeIcon from '@mui/icons-material/Home';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import LogoutIcon from '@mui/icons-material/Logout';
import BannerChromeExtension from './BannerChromeExtension';

import topickLogo from '../resources/logoNew.png';
import closeIcon from '../resources/closeIcon.png';
import logo4Circles from '../resources/logo4Circles.png';
import { AppContext } from '../context';
import { useNavigate } from 'react-router-dom';
import { AnalyticsEventNames, AnalyticsEventParams, logAnalyticsEvent } from '../Services/helpers';
import { constants } from '../Services/constants';

interface StyleProps {
    open: boolean;
}

const useStyles = createUseStyles({
    navbar: {
        minHeight: 785,
        height: '98vh',
        width: 270,
        backgroundColor: '#fff',
        borderRadius: '20px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        paddingBottom: 100,
        // applies only when the screen is less than 794px wide
        '@media (max-width: 794px)': {
            // display: 'none',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            height: '100vh',
            width: '70%',
            zIndex: 1000,
            borderRadius: 0,
            //     backgroundColor: '#fff',
            transition: 'transform 0.3s ease-out',
            transform: ({ open }: StyleProps) => open ? 'translateX(0)' : 'translateX(-100%)',
        },
    },

    logoSection: {
        height: 106,
        backgroundColor: '#000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        '@media (max-width: 794px)': {
            borderRadius: 0,
        }
    },
    logo: {
        width: 124,
    },
    // create container section that will have its elements spaced as far apart as possible vertically
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '90%',
    },
    homeIcon: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 43,
        paddingLeft: 33,
        color: '#848484',
        transition: 'background-image 0.3s ease-in-out',
        backgroundImage: 'none',
        '&:hover': {
            backgroundImage: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.1056) 0%, rgba(6, 210, 255, 0) 104.62%)',
            borderLeft: '6px solid #b62efc',
            cursor: 'pointer',
        },
    },
    homeText: {
        marginLeft: 12,
        color: '#848484',
    },
    bannerChromeExtension: {
    '@media (max-width: 794px)': 
    {
        display:'none !important',
    },
    '@media (min-width: 794px)': 
    {
        marginTop: 43,
        padding: '0 22px',
        paddingBottom: '20px',
    },    
    },
    logoutSection: {
        position: 'absolute',
        bottom: 0,
        height: 100,
        width: '100%',
        // backgroundColor: '#f2f2f2',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 33,
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        transition: 'background-image 0.3s ease-in-out',
        backgroundImage: 'none',
        '&:hover': {
            backgroundImage: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.1056) 0%, rgba(6, 210, 255, 0) 104.62%)',
            borderLeft: '6px solid #b62efc',
            cursor: 'pointer',
        },
    },
    logoutIcon: {
        display: 'flex',
        marginRight: 12,
        color: '#848484',
    },
    logoutText: {
        marginRight: 12,
        color: '#848484',
    },
    mobileNavbarHorizontal: {
        '@media (max-width: 794px)': {
            backgroundColor: '#000',
            padding: '25px 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& img': {
                maxHeight: '32px',
            },
        },
    },
    mobileLogo: {
        display: 'none',
        '@media (max-width: 794px)': {
            cursor: 'pointer',
            display: 'block',
        },
    },
    mobileCloseIcon: {
        display: 'none',
        '@media (max-width: 794px)': {
            cursor: 'pointer',
            position: 'absolute',
            top: 30,
            right: -13,
            display: ({ open }) => open ? 'block' : 'none',
        },
    },
    desktopOnly: {
        // show this section only when the screen is greater than 794px wide
        display: 'none',
        '@media (min-width: 794px)': {
            display: 'block',
        },
    },
    desktopOnlyFlex: {
        // show this section only when the screen is greater than 794px wide
        display: 'none',
        '@media (min-width: 794px)': {
            display: 'flex',
        },
    },
    mobileOnly: {
        // show this section only when the screen is less than 794px wide
        display: 'block',
        '@media (min-width: 794px)': {
            display: 'none',
        },
    },
    mobileOnlyFlex: {
        // show this section only when the screen is less than 794px wide
        display: 'flex',
        '@media (min-width: 794px)': {
            display: 'none',
        },
    },
});
interface Props {
    logEvent: (buttonId: string, componentName: string) => void;
}

const NavbarNew: React.FC<Props> = ({ logEvent }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles({ open });
    const { userActions } = useContext(AppContext);
    const navigate = useNavigate();


    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <div>
            <div className={classes.navbar}>
                <img src={closeIcon} alt="close Icon" className={classes.mobileCloseIcon} onClick={handleClick} />
                <div className={classes.logoSection}>
                    <img className={classes.logo} src={topickLogo} alt="Logo" />
                </div>
                <div className={classes.container}>
                    <div>
                        <div
                            className={classes.homeIcon}
                            onClick={() => navigate("/")}
                        >
                            <HomeIcon fontSize="large" />
                            <span className={classes.homeText}>Home</span>
                        </div>
                        {/* <div className={classes.homeIcon}>
                            <LightbulbCircleIcon fontSize="large" />
                            <span className={classes.homeText}>Help</span>
                        </div> */}
                        {/* <div className={classes.homeIcon}>
                            <HeadphonesIcon fontSize="large" />
                            <span className={classes.homeText}>Contact</span>
                        </div> */}
                        <div
                            className={`${classes.homeIcon} ${classes.mobileOnlyFlex}`}
                            onClick={() => userActions.onLogout()}
                        >
                            <LogoutIcon fontSize="large" />
                            <span className={classes.homeText}>Logout</span>
                        </div>
                    </div>
                    <div className={classes.bannerChromeExtension}>
                        <BannerChromeExtension logEvent={logEvent} />
                    </div>
                </div>
                {/* logout at the bottom */}
                {/* show only on desktop and not on mobile */}
                <div
                    className={`${classes.logoutSection} ${classes.desktopOnlyFlex}`}
                    onClick={() => userActions.onLogout()}
                >
                    <LogoutIcon
                        fontSize="large"
                        className={classes.logoutIcon}
                    />
                    <span className={classes.logoutText}>Logout</span>
                </div>
            </div>

            <div className={classes.mobileNavbarHorizontal}>
                <img
                    src={topickLogo}
                    alt="topick logo"
                    className={classes.mobileLogo}
                    onClick={() => navigate("/")}
                />
                <img src={logo4Circles} alt="topick logo" className={classes.mobileLogo} onClick={handleClick} />
            </div>

        </div>

    );
}

export default NavbarNew;

import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    circle: {
        width: 72,
        height: 72,
        borderRadius: '50%',
        background: 'linear-gradient(to right, #b62efc, #33c0fb)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    plus: {
        width: 30,
        height: 3,
        borderRadius: 2,
        background: '#fff',
        position: 'absolute',
    },
    plusVertical: {
        transform: 'rotate(90deg)',
    },
});

function Circle() {
    const classes = useStyles();
    return (
        <div className={classes.circle}>
            <div className={`${classes.plus} ${classes.plusVertical}`}></div>
            <div className={classes.plus}></div>
        </div>
    );
}

export default Circle;

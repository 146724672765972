import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
    name: string;
    image?: string;
    numberOfItems: number;
    title: string;
}

const useStyles = createUseStyles({
    header: {
        backgroundColor: 'black',
        padding: '25px 45px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '20px 20px 0 0',
        // enable wrap
        flexWrap: 'wrap',
        '@media (max-width: 927px)': {
            borderRadius: 0,
        },
    },
    title: {
        fontSize: '24px',
        fontWeight: 700,
        marginBottom: '5px',
        color: 'white',
        // You can add more styles here as needed
    },
    description: {
        fontSize: '16px',
        fontWeight: 400,
        color: 'white',
        // You can add more styles here as needed
    },
    emailContainer: {
        display: 'flex',
        alignItems: 'center',
        // You can add more styles here as needed
    },
    email: {
        fontSize: '16px',
        fontWeight: 700,
        color: 'white',
        marginRight: '10px',
        // You can add more styles here as needed
    },
    image: {
        width: '42px',
        height: '42px',
        borderRadius: '50%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        fontWeight: 'bold',
        // You can add more styles here as needed
    },
});

const Header: React.FC<Props> = ({ title, name, image, numberOfItems }) => {
    const classes = useStyles();
    let initials = '';
    const nameParts = name.split(' ');
    if (nameParts.length > 1) {
        // Extract the first letter of first name and first letter of last name
        initials = (nameParts[0][0] + nameParts[1][0]).toUpperCase();
    } else if (nameParts[0].length >= 2) {
        // If there is no last name, take the first two characters of the first name
        initials = nameParts[0].slice(0, 2).toUpperCase();
    } else if (nameParts[0].length === 1) {
        // If the first name doesn't have two characters, just take the first character
        initials = nameParts[0][0].toUpperCase();
    }
    return (
        <div className={classes.header}>
            <div>
                <div className={classes.title}>{title}</div>
                <div className={classes.description}>{`${numberOfItems} items`}</div>
            </div>
            <div className={classes.emailContainer}>
                <div className={classes.email}>{initials}</div>
                {image ? (
                    <img src={image} alt={name} className={classes.image} />
                ) : (
                    <div className={classes.image}>{initials}</div>
                )}
            </div>
        </div>
    );
};

export default Header;

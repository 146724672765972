import { createUseStyles } from "react-jss";
import yuppies from "../resources/yuppies.png";
// material ui
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";

const useStyles = createUseStyles({
  signUp: {},
  container: {
    background: "#F5F5F7",
    display: "flex",
    padding: "20px",
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'space-between'
  },
});

function SignUp({ signInWithGoogle }) {
  const classes = useStyles();

  return (
    <div className={classes.signUp}>
      <h2>Sign Up Menu</h2>
      <div className={classes.container}>
        <img src={yuppies} alt="yuppies" />
        <div className={classes.textSection}>
          <div>
            <h3>Say goodbye bookmarks </h3>
            <h3>Hello Topick</h3>
          </div>
          <div>
            <p>Create your first wishlist</p>
            <Button
              variant="outlined"
              startIcon={<GoogleIcon />}
              fullWidth
              onClick={signInWithGoogle}
            >
              Continue with Google
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

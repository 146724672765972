// create a react component
import React, { useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

// local imports
import BannerChromeExtension from '../../components/BannerChromeExtension';
import CardsContainer from '../../components/CardsContainer';
import CardWithImages from '../../components/CardWithImages';
import Layout from "../../components/Layout";
import BannerWithButton from '../../components/BannerWithButton';
import TwoColumnLayout from '../../components/TwoColumnLayout';
import AddNewCard from '../../components/AddNewCard';
import CustomModal from '../../components/CustomModal';
import NavbarNew from '../../components/NavbarNew';

// helpers and constants
import { AnalyticsEventNames, AnalyticsEventParams, DropdownMenuOption, defaultDropdownOptions, logAnalyticsEvent } from '../../Services/helpers'
import { constants } from '../../Services/constants';
import { AppContext } from '../../context';
import { UserInfo, DbOperations } from '../../App';
// material UI
import { Snackbar } from "@mui/material";
import { Typography } from '@mui/material';



const useStyles = createUseStyles({
    bannerChromeExtension: {
        display: 'none',
        // visible only on mobile
        '@media (max-width: 794px)': {
            display: 'none',
            //marginTop: '20px',
        },
    },
});

function fetchMissingListsData(userInfo: UserInfo, dbOperations: DbOperations) {
    userInfo.lists.forEach((listId) => {
        if (!userInfo.listsData || !userInfo.listsData.some((list) => list.id === listId)) {
            dbOperations.fetchListItems(listId);
        }
    });
}

const dropdownOptions = defaultDropdownOptions;

const pageName = constants.ANALYTICS_IDS.PAGES.DASHBOARD.PAGE_NAME;
const pageLocation = constants.ANALYTICS_IDS.PAGES.DASHBOARD.PAGE_LOCATION;

const logButtonClickEvent = (buttonId: string, componentName: string) => {
    const eventParam: AnalyticsEventParams = {
        buttonId,
        componentName,
        pageName,
        pageLocation,
    };
    logAnalyticsEvent(AnalyticsEventNames.BUTTON_CLICK, eventParam);
}

function DashboardNew() {
    const classes = useStyles();
    const { userInfo, dbOperations } = useContext(AppContext);
    const [currentListId, setCurrentListId] = React.useState('');
    const [isCreateNewListModalOpen, setIsCreateNewListModalOpen] = React.useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const [isDeleteWarningSnackbarOpen, setIsDeleteWarningSnackbarOpen] = React.useState(false);
    // fetch data from db
    useEffect(() => {
        fetchMissingListsData(userInfo, dbOperations);
    }, [userInfo, dbOperations]);

    // extract listsData from userInfo object
    // make listsData as a state variable
    const [listsData, setListsData] = React.useState(userInfo.listsData || []);
    // const listsData = userInfo.listsData || [];
    // update listsData when userInfo.listsData changes
    useEffect(() => {
        setListsData(userInfo.listsData || []);
    }, [userInfo.listsData]);


    const closeModal = () => {
        setIsCreateNewListModalOpen(false);
        setIsEditModalOpen(false);
        setIsDeleteModalOpen(false);
    }

    const handleSubmitCreateNewList = async (value: string) => {
        const newListName = value;
        // close the modal
        closeModal();
        // create a new list
        const islistCreationSuccessfull = await dbOperations.createList(newListName);
        if (islistCreationSuccessfull) {
            await dbOperations.fetchUserInfo(userInfo.uid, true);
        }
    }

    const handleSubmitRenameList = async (value: string) => {
        const listId = currentListId;
        const newListName = value;
        // close the modal
        closeModal();
        // update the list with new name
        const islistUpdateSuccessfull = await dbOperations.updateListName(listId, newListName);
        if (islistUpdateSuccessfull) {
            // fetch the details of the list and update the listData
            const listData = await dbOperations.fetchListDetailsById(listId, true);
            if (listData) {
                const listsData = userInfo.listsData || [];
                // find first object with same id and update it
                const index = listsData.findIndex((list) => list.id === listId);
                if (index !== -1) {
                    listsData[index].name = listData.name;
                    // cause a re-render
                    const newListsData = userInfo.listsData || [];
                    setListsData([...newListsData]);
                }
            }
        } else {
            console.log('error updating list name');
        }
    }

    const handleSubmitDeleteList = async () => {
        const listId = currentListId;
        // close the modal
        closeModal();
        // delete the list if number of list is greater than 1
        if (userInfo.lists.length <= 1) {
            setIsDeleteWarningSnackbarOpen(true);
            return;
        }
        const islistDeletionSuccessfull = await dbOperations.deleteList(listId);
        if (islistDeletionSuccessfull) {
            // remove the list id from userInfo.lists
            const index = userInfo.lists.findIndex((id) => id === listId);
            if (index !== -1) {
                // remove the list id from userInfo.lists
                userInfo.lists.splice(index, 1);
                // remove the list data from userInfo.listsData
                const listsData = userInfo.listsData || [];
                const index2 = listsData.findIndex((list) => list.id === listId);
                if (index2 !== -1) {
                    listsData.splice(index2, 1);
                    // cause a re-render
                    const newListsData = userInfo.listsData || [];
                    setListsData([...newListsData]);
                }
            }
        } else {
            console.log('error deleting list');
        }
    }


    const handleDropdownMenuOptionClick = (label: string, listId: string) => {
        setCurrentListId(listId);
        if (label === 'Edit') {
            // launch modal with edit option
            setIsEditModalOpen(true);
        } else if (label === 'Delete') {
            // launch modal with delete option
            setIsDeleteModalOpen(true);
        }
    }

    const handleClickShareList = (listId: string) => {
        const listPageUrl = `${window.location.origin}/list/${listId}`;
        navigator.clipboard.writeText(listPageUrl);
        setIsSnackbarOpen(true);
    }
    const handleClickAddNewList = (buttonId: string, componentName: string) => {
        // log event to analytics
        logButtonClickEvent(buttonId, componentName);
        setIsCreateNewListModalOpen(true);
    }

    return (
        <>
            <Layout>
                <TwoColumnLayout>
                    <NavbarNew logEvent={logButtonClickEvent} />
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <BannerWithButton
                                    title={`Hello ${userInfo.name}! 😊`}
                                    description="It's good to see you. Let's create a wishlist!"
                                    handleClick={handleClickAddNewList} />
                            </div>
                            <div className={classes.bannerChromeExtension}>
                                <BannerChromeExtension isHorizontal logEvent={logButtonClickEvent} />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                    component="h2"
                                    sx={{ marginBottom: 2, marginTop: 4 }}
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '24px',
                                    }}
                                >
                                    {"My Wish List"}
                                </Typography>
                                <CardsContainer>
                                    
                                    {listsData.map((list) => {
                                        // within the list.items array, find the first item with image or else return the first item
                                        let image1 = undefined;
                                        let image2 = undefined;
                                        const itemsWithImage = list.items.filter(
                                            (item) => item[Object.keys(item)[0]].img !== ''
                                        );
                                        if (itemsWithImage.length >= 1) {
                                            image1 = itemsWithImage[0][Object.keys(itemsWithImage[0])[0]].img;
                                        }
                                        if (itemsWithImage.length >= 2) {
                                            image2 = itemsWithImage[1][Object.keys(itemsWithImage[1])[0]].img;
                                        }
                                        return (<CardWithImages
                                            image1={image1}
                                            image2={image2}
                                            labelHeading={list.name}
                                            labelDescription={`${list.items.length} items`}
                                            key={list.id}
                                            id={list.id}
                                            dropdownOptions={dropdownOptions}
                                            onOptionClick={(label) => {
                                                const listId = list.id;
                                                handleDropdownMenuOptionClick(label, listId);
                                            }}
                                            onShareClick={handleClickShareList}
                                        />)
                                    })}
                                    <AddNewCard onClick={handleClickAddNewList} />
                                </CardsContainer>
                            </div>
                        </div>
                    </>
                </TwoColumnLayout>
            </Layout>

            <CustomModal
                header='Create A New List'
                isOpen={isCreateNewListModalOpen}
                onClose={closeModal}
            >
                <Form
                    inputPlaceholder='Enter List Name...'
                    onSubmit={handleSubmitCreateNewList} />
            </CustomModal>
            <CustomModal
                header='Update List Name...'
                isOpen={isEditModalOpen}
                onClose={closeModal}
            >
                <Form
                    inputPlaceholder='Enter new name...'
                    onSubmit={handleSubmitRenameList}
                    submitButtonText='Update'
                />
            </CustomModal>
            <CustomModal
                header='Are you sure you want to delete this list?'
                isOpen={isDeleteModalOpen}
                onClose={closeModal}
            >
                <Form
                    onSubmit={handleSubmitDeleteList}
                    submitButtonText='Delete'
                    showTextArea={false}
                />
            </CustomModal>
            <Snackbar
                open={isSnackbarOpen}
                onClose={() => setIsSnackbarOpen(false)}
                autoHideDuration={2000}
                message="Link Copied to clipboard"
            />
            <Snackbar
                open={isDeleteWarningSnackbarOpen}
                onClose={() => setIsDeleteWarningSnackbarOpen(false)}
                autoHideDuration={10000}
                message="Cannot delete list. This is your last list. Consider creating a new list before deleting the current one. "
            />
        </>
    );
}

interface FormProps {
    onSubmit: (value: string, listId?: string) => void;
    inputPlaceholder?: string;
    submitButtonText?: string;
    showTextArea?: boolean;
    dropdownItems?: DropdownItems[];
}

export interface DropdownItems {
    label: string;
    value: string;
}
interface FormStylesProps {
    disabled: boolean;
}

const useFormStyles = createUseStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dropdown: {
        padding: '10px',
        border: '2px solid #ccc',
        borderRadius: '5px',
        width: '100%',
        margin: '20px',
        '&:focus': {
            outline: 'none',
            border: '2px solid #1a73e8',
        },
    },
    input: {
        padding: '10px',
        border: '2px solid #ccc',
        borderRadius: '5px',
        width: '100%',
        margin: '20px',
        '&:focus': {
            outline: 'none',
            border: '2px solid #1a73e8',
        },
    },
    button: ({ disabled }: FormStylesProps) => ({
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: 'black',
        fontWeight: 'bold',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        opacity: disabled ? 0.5 : 1,
        width: '100%',
        '&:disabled': {
            cursor: 'not-allowed',
        },
    }),
    error: {
        color: "#FF4136",
        marginTop: "1rem",
    },
});
// TODO: extract this to a separate file
export const Form: React.FC<FormProps> = ({ 
    onSubmit, 
    inputPlaceholder, 
    submitButtonText = "Submit", 
    showTextArea = true,
    dropdownItems = [],
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const [isValid, setIsValid] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    // Initialize the selectedDropdownValue state with the value of the first item
    const [selectedDropdownValue, setSelectedDropdownValue] = React.useState(dropdownItems[0]?.value || '');
    // Disable the button if there is no text entered
    const isButtonDisabled = !isValid && showTextArea;
    const classes = useFormStyles({ disabled: isButtonDisabled });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // check if dropdown is present
        if (dropdownItems?.length > 0) {
            onSubmit(selectedDropdownValue);
            setSelectedDropdownValue('');
            return;
        }
        onSubmit(inputValue.trim());
        setInputValue('');
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        // setIsValid to be false if thee value contains any special character or it is blank or empty
        setInputValue(value);
        if (value.trim() === '') {
            setIsValid(false);
            setErrorMessage('List name cannot be empty');
            return;
        }
        else if (!value.match(/^[A-Za-z0-9 ]*$/)) {
            setIsValid(false);
            setErrorMessage('Only alphabets, numbers, and space are allowed.');
            return;
        }
        // value cannot be longer than 18 characters
        else if (value.length > 18) {
            setIsValid(false);
            setErrorMessage('List name cannot be longer than 18 characters.');
            return;
        }
        setIsValid(true);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            {showTextArea && (
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleTextChange}
                    placeholder={inputPlaceholder}
                    className={classes.input}
                />
            )}
            {dropdownItems?.length > 0 && (
                <select 
                    className={classes.dropdown}
                    value={selectedDropdownValue} // Set the value of the select to the state variable
                    onChange={(e) => setSelectedDropdownValue(e.target.value)} // Update the state variable when the select changes
                >
                    {dropdownItems.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                </select>
            )}
            <button type="submit" disabled={isButtonDisabled} className={classes.button}>
                {submitButtonText}
            </button>
            {!isValid && (
                <div className={classes.error}>
                    {errorMessage}
                </div>
            )}
        </form>
    );
};


export default DashboardNew;
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import React from "react";

export const FormInputText = ({ name, control, label, defaultValue, disabled = false, sx, rules, required = false }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ fieldState, field: { onChange, value, ref } }) => (
        <>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ fontWeight: "bold", color: "black", marginTop: '20px' }}
            sx={{ mt: 4 }}
          >
            {label}
          </InputLabel>
          <TextField
            onChange={onChange}
            value={value}
            inputRef={ref}
            fullWidth
            multiline
            disabled={disabled}
            sx={sx}
            error={!!fieldState.error}
            size='small'
          // required={required}
          />
        </>
      )}
    />
  );
};

export default FormInputText;

import React, { useRef, useEffect, useContext,useState  } from 'react';
import { createUseStyles } from 'react-jss';
import Text from './Text';
import VCard from './VCard';
// resources
import shareIcon from '../resources/shareIcon.png';
import menuIcon from '../resources/menuIcon.png';
import emptyBag from '../resources/emptyBag.png';
import acquiredIconBadge from '../resources/acquiredIconBadge.svg';
import acquiredIconBadge_y from '../resources/acquiredIconBadge_y.svg';

import Card from './Card';
import CenteredContainer from './CenteredContainer';
import DropdownMenu from './DropdownMenu';
import { useNavigate } from 'react-router-dom';
import { DropdownMenuOption } from '../Services/helpers'
import { Typography } from '@mui/material';
import { DropdownMenuVariants } from './DropdownMenu';
import { ItemDetails } from '../App';
import { AnalyticsEventNames, AnalyticsEventParams, logAnalyticsEvent } from '../Services/helpers'
import { constants } from '../Services/constants';

interface StyleProps {
    isAcquired?: boolean;
}

const useStyles = createUseStyles({
    card: {
        width: '192px',
        borderRadius: '10px',
        backgroundColor: '#E0E3E2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        maxHeight:'230px',
         // apply only to mobile
         '@media (max-width: 794px)': {
            width: '100%',
            height:'180px',
            maxHeight:'180px',
        },
       
    },
    image: {
        // apply only to mobile
        '@media (min-width: 794px)': {
        width: '159px',
        height: '139px',
        borderRadius: '10px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
    },
        // apply only to mobile
        '@media (max-width: 794px)': {
        width: '100px',
        height: '80%',
        borderRadius: '10px',
        position: 'absolute',
        top: '10%',
        left: '5%',
        cursor: 'pointer',
        },
    },
    imageRight: {
        '@media (min-width: 794px)': {
        transform: 'translate(-50%, -50%) rotate(5deg)',
        cursor: 'pointer',
        }
    },
    imageLeft: {
        '@media (min-width: 794px)': {
        transform: 'translate(-50%, -50%) rotate(-10deg)',
        cursor: 'pointer',
        }
    },
    label: {
        '@media (min-width: 794px)': {
        position: 'absolute',
        top: '110px',
        width: '200px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height:'120px',
    },
    '@media (max-width: 794px)': {
        position: 'absolute',
        top: '0px',
        maxHeight:'150px',
        width: '60%',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:'none',
    },
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        position: 'relative',
        right:'8px',
        top:'5px',
        //only for mobile
        '@media (max-width: 794px)': {
            top:'30px',
            left:'5px',
        }
    },
    labelContent: {
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    icon: {
        width: '28px',
        height: '28px',
        cursor: 'pointer',
        '&:not(:last-child)': {
            marginRight: '10px',
        }
    },
    listImageWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: '10px',
         // apply only to mobile
         '@media (max-width: 794px)': {
        width: '30%',
        height: '90%',
        right:'30%',
         }
        
    },
    listImage: {
        width: '100%',
        height: 'auto',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        verticalAlign: 'top',
        cursor: 'pointer',
        // apply only to mobile
        '@media (max-width: 794px)': {
            position: 'relative',
            borderRadius:'5px'
             }
    },
    truncatedText1: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
         // apply only to mobile
         '@media (max-width: 794px)': {
            maxHeight:'65px',
            whiteSpace: 'initial',
             }
        
    },
    truncatedText: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    acquiredBadge: {
        
        position: 'relative',
        borderRadius: '10%',
        height:'35px',
        width:'35px',
        //bottom:'25px',
        background: 'none',
        //right: '5%',
        //left:'80%',
        top:'140px',
        //width: 'fit-content',
        padding: '2px 5px',
        cursor:'pointer',
        // only display the badge if the item is acquired
        display: ({ isAcquired }: StyleProps) => isAcquired ? 'block' : 'block',
        color: ({ isAcquired }: StyleProps) => isAcquired ? 'green' : 'grey',
        // apply only to mobile
        '@media (max-width: 794px)': {
            position:'absolute',
            right:'1px',
            bottom:'5px',
            left:'unset',
            }
    },
    acquiredText:{
        //fontSize: 'x-small',
        position: 'absolute',
        top:'170px',
        right: '40px',
        fontSize:'12px',
        
         // apply only to mobile
         '@media (max-width: 794px)': {
            position: 'absolute',
            fontSize:'10px',
            right:'40px',
            top:'140px',
            }
    
    },
    notAcquiredText:{
        //fontSize: 'x-small',
        position: 'absolute',
        top:'170px',
        right: '35px',
        fontSize:'12px',
        '@media (min-width: 794px)': {
        width:'75px'
        },
         // apply only to mobile
         '@media (max-width: 794px)': {
            position: 'absolute',
            fontSize:'10px',
            right:'30px',
            top:'140px',
            width:'75px'
            }
    
    },
    btnWrapper: {
        display: 'flex',
        // make buttons take up equal space but with a 10px gap between them and occupy the extreme left and right of the card
        justifyContent: 'space-between',
        width: '100%',
        columnGap: '7px',
        marginTop: '21px',
    },
    btn: {
        width: '50%',
        borderRadius: '6px',
        padding: '7px 0',
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '16px',
        border: 'none',
        cursor: 'pointer',
    },
    btnLeft: {
        background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
        color: '#FFFFFF',
        boxShadow: '2px 2px 9px rgba(0, 0, 0, 0.15)',
        '&:hover': {
            background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
        }
    },
    btnRight: {
        background: '#E1E3E2',
        color: '#000000',
    },
    dropdownWrapper: {
        position: 'absolute',
        top: '12px',
        right: '12px',
        zIndex: 1,
         // apply only to mobile
         '@media (max-width: 794px)': {
        top: '5px',
        right: '5px',
        }
    },
    
});

interface CardWithImagesProps {
    image1?: string;
    image2?: string;
    labelHeading?: string;
    labelDescription?: string;
    id: string;
    dropdownOptions?: DropdownMenuOption[],
    onOptionClick?: (optionLabel: string) => void,
    onShareClick?: (listId: string) => void
    variant?: CardWithImageVariants
    listItemDetails?: ItemDetails
    handleToggleIsDone?: (isDone: boolean) => void
}

export enum CardWithImageVariants {
    WISHLIST = 'WISHLIST',
    LIST_ITEM = 'LIST_ITEM',
    LIST_ITEM_PUBLIC = 'LIST_ITEM_PUBLIC'
}




const CardWithImages: React.FC<CardWithImagesProps> = ({ image1, image2, labelHeading, labelDescription, id, dropdownOptions, onOptionClick, onShareClick, variant = CardWithImageVariants.WISHLIST, listItemDetails, handleToggleIsDone }) => 
{
    const [showText, setShowText] = useState(false);
    const classes = useStyles({ isAcquired: listItemDetails?.[id]?.isDone ?? false });
    const navigate = useNavigate();
    const parentRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    const handleHeadingClick = () => {
        navigate(`/list/${id}`);
    }

    const adjustParentHeight = () => {
        if (parentRef.current && childRef.current) {
            // the offset from top of the absolutely positioned child to the top of the parent
            const offsetOfChild = childRef.current.offsetTop;
            // the height of the child
            const heightOfChild = childRef.current.offsetHeight;
            // the height of the parent
            const heightOfParent = parentRef.current.offsetHeight;
            // the height of the parent after the child is absolutely positioned
            const newHeightOfParent = offsetOfChild + heightOfChild;
            // if the height of the parent is less than the height of the parent after the child is absolutely positioned

            parentRef.current.style.height = `${Math.max(newHeightOfParent, heightOfParent)}px`;
        }
    };

    useEffect(() => {
        adjustParentHeight();
        window.addEventListener('resize', adjustParentHeight);

        return () => {
            window.removeEventListener('resize', adjustParentHeight);
        };
    }, []);



    if (variant === CardWithImageVariants.WISHLIST) 
    {
        const pageName = constants.ANALYTICS_IDS.PAGES.DASHBOARD.PAGE_NAME;
        const pageLocation = constants.ANALYTICS_IDS.PAGES.DASHBOARD.PAGE_LOCATION;

        const logButtonClickEvent = (buttonId: string, componentName: string) => {
            const eventParam: AnalyticsEventParams = {
              buttonId,
              componentName,
              pageName,
              pageLocation,
            };
            logAnalyticsEvent(AnalyticsEventNames.BUTTON_CLICK, eventParam);
          }

          //GA event handlers for Share button on item page
        const shareButtonClickHandler = () => {
        logButtonClickEvent(constants.ANALYTICS_IDS.COMPONENTS.SHARE_BUTTON.SHARE_BUTTON_ID, constants.ANALYTICS_IDS.COMPONENTS.SHARE_BUTTON.COMPONENT_NAME);
        onShareClick(id);
    }

        return (
            <>
                <div className={classes.card} ref={parentRef} >
                    {/* Image on the back */}
                    {image2 ?
                        <img src={image2} className={`${classes.image} ${classes.imageRight}`} alt="Image 1" />
                        :
                        <div className={`${classes.image} ${classes.imageRight}`}>
                            <Card backgroundColor={"rgba(196, 196, 199, 0.3)"} height={'100%'} />
                        </div>
                    }
                    {/* Image on the front */}
                    {image1 ?
                        <img src={image1} className={`${classes.image} ${classes.imageLeft}`} alt="Image 2" onClick={handleHeadingClick}/>
                        :
                        <div className={`${classes.image} ${classes.imageLeft}`}>
                            <CenteredContainer backgroundColor={"#C4C4C4"} height={'100%'} >
                                <img src={emptyBag} alt="plus" width="40px" />
                            </CenteredContainer>
                        </div>
                    }
                    <div className={classes.label} ref={childRef}>
                        <VCard>
                            {/* extract inline style to classes */}
                            <div className={classes.labelContent}>
                                <div>
                                    <span style={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }} onClick={handleHeadingClick}>{labelHeading}</span>
                                    <br />
                                    <span style={{ fontSize: '14px', color: '#848484' }}>{labelDescription}</span>
                                </div>

                                <div className={classes.iconWrapper}>
                                    <img className={classes.icon} src={shareIcon} alt="plus" onClick={shareButtonClickHandler}/>
                                    <DropdownMenu dropdownOptions={dropdownOptions} onOptionClick={onOptionClick} />
                                </div>
                            </div>
                        </VCard>
                    </div>
                    
                </div>

            </>
        );
    }
    else {
        

        const pageName = constants.ANALYTICS_IDS.PAGES.USER_LIST.PAGE_NAME;
        const pageLocation = constants.ANALYTICS_IDS.PAGES.USER_LIST.PAGE_LOCATION;

        const logButtonClickEvent = (buttonId: string, componentName: string) => {
            const eventParam: AnalyticsEventParams = {
              buttonId,
              componentName,
              pageName,
              pageLocation,
            };
            logAnalyticsEvent(AnalyticsEventNames.BUTTON_CLICK, eventParam);
          }

        const itemObject = listItemDetails[id]
        
        const handleClickGotToStore = (url: string) => {
            // TODO: add analytics
            logButtonClickEvent(constants.ANALYTICS_IDS.COMPONENTS.GO_TO_STORE.GO_TO_STORE_BUTTON, constants.ANALYTICS_IDS.COMPONENTS.GO_TO_STORE.COMPONENT_NAME);
            window.open(url, '_blank');
        }
        const handleAcquired = (acquiredFlag: boolean) => {
            // TODO: add analytics
            setTimeout(() => {
                setShowText(false);
                 
            }, 2000); // 5000 milliseconds = 5 seconds    
            logButtonClickEvent(constants.ANALYTICS_IDS.COMPONENTS.ACQUIRED.BUTTON, constants.ANALYTICS_IDS.COMPONENTS.ACQUIRED.COMPONENT_NAME);
            setShowText(true);
            
                setTimeout(() => {
                
                handleToggleIsDone(!acquiredFlag)  
                }, 2000); // 5000 milliseconds = 5 seconds
            
        }
        

        return (
            <div className={classes.card} ref={parentRef} >
                {itemObject.img &&
                    <div className={classes.listImageWrapper} onClick={() => handleClickGotToStore(itemObject.url)}>
                        <img src={itemObject.img} className={classes.listImage} alt="Item Image" />
                    </div>
                }
                <div className={classes.label} ref={childRef}>
                    <VCard>
                        <Typography sx={{ fontWeight: 400, fontSize: '14px' }}
                            className={classes.truncatedText1} onClick={() => handleClickGotToStore(itemObject.url)}
                        >
                            {itemObject.title ? itemObject.title : 'N/A'}
                        </Typography>
                        <Typography
                            className={classes.truncatedText}
                            sx={{ fontSize: '14px', fontWeight: 600 }}
                        >
                            {itemObject.price ? itemObject.price : 'N/A'}
                        </Typography>
                            {variant === CardWithImageVariants.LIST_ITEM &&
                            (<div>
                                
                            </div>
                            )
                            }
                        

                    </VCard>
                </div>
                {/* show if variant is list_item */}
                {variant === CardWithImageVariants.LIST_ITEM &&
                    <div className={classes.dropdownWrapper}>
                        <DropdownMenu
                            dropdownOptions={dropdownOptions}
                            onOptionClick={onOptionClick}
                            variant={DropdownMenuVariants.WHITE}
                        />
                    {/* <Typography sx={{ fontSize: '10px', fontWeight: 600, color: 'white' }}>Acquired</Typography> */}
                    <img className={classes.acquiredBadge} src={itemObject.isDone ? acquiredIconBadge_y:acquiredIconBadge } alt="Image_1" onClick={() => handleAcquired(itemObject.isDone)} />
                    {itemObject.isDone ? showText && <p className={classes.notAcquiredText}>Not Acquired</p> : showText && <p className={classes.acquiredText}>Acquired</p>}    
                    </div>
                }

            </div >

        )

    }
}

export default CardWithImages;

import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    vcard: {
        // border: '1px solid #ccc',
        
         // apply only to mobile
         '@media (max-width: 794px)': {
        borderTop: 'none',
        position: 'relative',
        width: '100%',
        borderRadius: '10px',
        //boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
        },
        // apply only to desktop
        '@media (min-width: 794px)': {
            borderTop: 'none',
            position: 'relative',
            // overflow: 'hidden',
            width: '100%',
            // backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            height:'120px',
        },
    },
    topBorder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '20px',
        // backgroundColor: '#fff',
        borderTop: 'none',
    },
    vcardTopLeft: {
        // apply only to desktop
        '@media (min-width: 794px)': {
        position: 'absolute',
        top: '-1px',
        left: 0,
        right: 0,
        height: '20px',
        backgroundColor: '#fff',
        borderTop: 'none',
        // boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
        transform: 'skewY(3deg)',
        transformOrigin: '0 0',
        },
        // apply only to mobile
        '@media (max-width: 794px)': {
            display:'none',
        }
    },
    vcardTopRight: {
        position: 'absolute',
        top: '-1px',
        left: 0,
        right: 0,
        height: '20px',
        backgroundColor: '#fff',
        borderTop: 'none',
        // boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
        transform: 'skewY(-3deg)',
        transformOrigin: 'top right',
        // apply only to mobile
        '@media (max-width: 794px)': {
            display:'none',
        }
        
    },
    vcardTopPadding: {
        paddingTop: '20px',
    },
    vcardContent: {
        padding: '4px 20px 20px',
        backgroundColor: '#fff',
        borderRadius: '0 0 10px 10px',
        height:'102px',
        // apply only to mobile
        '@media (max-width: 794px)': {
            backgroundColor: 'transparent',
            border:'none',
            position:'absolute',
            left:'30%',
            top:'30px',
            width:'175px',
        }
    },
});

function VCard(props) {
    const classes = useStyles();
    return (
        <div className={classes.vcard}>
            <div className={classes.topBorder}>

                <div className={classes.vcardTopLeft}></div>
                <div className={classes.vcardTopRight}></div>
            </div>
            {/* a div with padding of 20 px on the top */}
            <div className={classes.vcardTopPadding} />

            <div className={classes.vcardContent}>{props.children}</div>
        </div>
    );
}

export default VCard;

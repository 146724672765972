import { createUseStyles } from "react-jss";
import React, { useContext, useState } from "react";
import logo from "../resources/logo.png";
// external dep
import { useForm } from "react-hook-form";
// mui
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { Box, Button, Grid, Link } from "@mui/material";
import { FormInputText } from "../helperComponents/FormInputText";
import { FormInputSelect } from "../helperComponents/FormInputSelect";
import { Restore, OpenInNew } from "@mui/icons-material";
import { useEffect } from "react";
import { ListDetails } from "../App";
import { AppContext } from "../context";

// source - https://blog.logrocket.com/using-material-ui-with-react-hook-form/

const useStyles = createUseStyles({
  body: {
    //padding: "20px",
    left: "3.5%",
    right: "3.5%",
    position: "relative",
    width: "93%",
    //background: "rgb(242 242 242)",
  },
  row: {
    marginTop: '2%',
  },
  goToSeller: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      left: '80%',
    },
    '@media only screen and (max-width: 768px)': {
      //width: "60%",
    },
  },
  saveAndCreate: {
    //desktop
    '@media only screen and (min-width: 768px)': {
      width: '33%',
      //left: '1.5%',
      //top: '8%',
      marginTop: '30px',
      position: 'relative',
      background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
      color: 'white',
      borderRadius: '15px',
      height: '40px',
    },
    '@media only screen and (max-width: 768px)': {
      width: '50%',
      left: '25%',
      marginTop: '20px',
      position: 'relative',
      background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
      color: 'white',
      borderRadius: '10px',
      height: '30px',
      fontSize: '12px',
      fontWeight: '600',
    },
  },
});

interface SaveItemFormProps {
  control: any;
  setValue: any;
  register: any;
  defaultListValue?: string;
  title?: string;
  description?: string;
  price?: string;
  currency?: string;
  url?: string;
}

const SaveItemForm: React.FC<SaveItemFormProps> = ({
  control,
  setValue,
  register,
  defaultListValue,
  title = "",
  description = "",
  price = "",
  currency = "",
  url = "",
}) => {
  const classes = useStyles();
  const { userInfo } = useContext(AppContext);
  const [listsDetails, setListsDetails] = useState<ListDetails[]>([]);

  useEffect(() => {
    setValue("title", title);
    setValue("description", description);
    setValue("price", `${currency} ${price}`.trim());
    setValue("url", url);
  }, [title, description, price, currency, url, setValue]);

  useEffect(() => {
    setListsDetails(userInfo?.listsDetails);
  }, [userInfo]);

  return (
    <Box
      // component="form"
      className={classes.body}
    // onSubmit={handleSubmit(onSubmit)}
    >

      {listsDetails?.length > 0 && (
        <FormInputSelect
          name={"listId"}
          control={control}
          label={"List Name"}
          defaultValue={defaultListValue ? defaultListValue : listsDetails[0].id}
          disabled={false}
          options={listsDetails.map((list) => ({ label: list.name, value: list.id }))}
          sx={{ background: "white" }}
        />
      )}

      <FormInputText
        name={"title"}
        control={control}
        label={"Title"}
        defaultValue={title}
        sx={{ background: "white" }}
        rules={{ required: true }}
      // required
      />

      <FormInputText
        name={"description"}
        control={control}
        label={"Description"}
        defaultValue={description}
        sx={{ background: "white" }}
      />

      <FormInputText
        name={"price"}
        control={control}
        label={"Price (optional)"}
        defaultValue={`${currency} ${price}`.trim()}
        sx={{ background: "white" }}
      />

      <input className={classes.goToSeller} type="hidden" {...register("url")} />

      {url && (
        <Grid container sx={{ mt: 3, mb: 2, position: 'relative', left: '80%' }}>
          <Grid item xs>
            <Link
              href={url}
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Seller
              {/* <OpenInNew sx={{ fontSize: "15px"}}/> */}
            </Link>
          </Grid>
          {/* <Grid item>
            <Button endIcon={<Restore />}/>
          </Grid> */}
        </Grid>
      )}
      {/* <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
    <Button onClick={() => reset()} variant={"outlined"}>Reset</Button> */}
      <Button type="submit" className={classes.saveAndCreate}>Save</Button>
    </Box>
  );
}

export default SaveItemForm;

// TODO:
// 1. Update link to the actual link
// 2. Only show the link when there is a link
// 2. while savind details, link has to be included. the default form object doesn't include link

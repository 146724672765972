import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useParams,
    useLocation,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { AppContext } from "./context";

// external utils
import * as _ from "lodash";
import { createUseStyles } from 'react-jss';

import React, { useState, ReactNode, ReactElement, useEffect } from "react";
// firebase
import { auth, provider, db, fetchUrlDetails } from "./firebaseConfig";
import { signInWithPopup, User, UserCredential, Auth } from "firebase/auth";
import {
    collection,
    updateDoc,
    doc,
    getDoc,
    setDoc,
    addDoc,
    getDocs,
    deleteDoc,
    serverTimestamp,
    arrayUnion,
    arrayRemove,
    DocumentReference,
    FieldValue,
    query,
    orderBy,
    DocumentSnapshot,
    DocumentData,
} from "firebase/firestore";
import { signOut } from "firebase/auth";

// pages
import Login from "./Pages/Login";
import AddProduct from "./Pages/AddProduct";
import UserList from "./Pages/UserListNew";
import Loading from "./Pages/Loading";
import DeleteAccount from "./Pages/DeleteAccount";
// components

// resources
// import sampleImage1 from "./resources/sample_image_1.jpg";
import DashboardNew from "./Pages/DashboardNew";
import { constants } from './Services/constants';
import UserListPublic from "./Pages/UserListPublic";



const useStyles = createUseStyles({
    '@global': {
        body: {
            margin: 0,
            padding: 0,
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '1.5',
            fontWeight: 400,
            backgroundColor: '#E0E3E2',
            overflowX: 'hidden'
        }
    }
});

interface UserInfoDbObject {
    lists: string[];
    name: string;
    creationTime: string;
    lastSignInTime: string;
    photoURL: string;
    email: string;
}

export interface UserInfo extends UserInfoDbObject {
    uid: string;
    listsData?: ListData[];
    listsDetails: ListDetails[];
}

interface ListDbObject {
    name: string;
    owner: string;
    createdT: FieldValue;
    updatedT: FieldValue;
}

export interface ListDetails extends ListDbObject {
    id: string;
}

export type ListData = {
    id: string;
    name: string;
    createdT: FieldValue;
    updatedT: FieldValue;
    items: ItemDetails[];
};
export type ItemDetails = {
    [id: string]: ItemObject;
};
export type ItemObject = {
    url: string;
    title: string;
    description: string;
    img: string;
    price: string;
    currency: string;
    isDone: boolean;
    createdT: FieldValue;
    updatedT: FieldValue;
};
// object to be used while updating item details in list
export type ItemUpdateObject = {
    url?: string;
    title?: string;
    description?: string;
    img?: string;
    price?: string;
    currency?: string;
    isDone?: boolean;
    updatedT: FieldValue;
};

type RequireAuthProps = {
    isAuth: boolean | string;
    children: ReactNode;
};
export type DbOperations = {
    fetchListItems: (listId: string, updateState?: Boolean) => Promise<ListData>;
    toggleItemIsDone: (listId: string, itemId: string, isDone?: boolean) => Promise<void>;
    updateItemDetailsInList: (listId: string, itemId: string, item: ItemUpdateObject) => Promise<boolean>;
    fetchListItem: (listId: string, itemId: string, updateState?: boolean) => Promise<DocumentData>;
    fetchPublicListItems: (listId: string) => Promise<[ItemDetails[], boolean]>;
    fetchListDetailsById: (listId: string, updateUserInfoFlag: boolean) => Promise<ListDbObject>;
    deleteItemFromList: (listId: string, itemId: string) => Promise<boolean>;
    fetchUserName: (userRef: string) => Promise<string>;
    createList: (listName: string) => Promise<boolean>;
    fetchUserInfo: (uid: string, updateUserInfoFlag: boolean) => Promise<UserInfo>;
    updateListName: (listId: string, listName: string) => Promise<boolean>;
    deleteList: (listId: string, updateState?: boolean) => Promise<boolean>;
    addItemToList: (listId: string, item: ItemObject) => Promise<boolean>;
    moveItemToList: (listId: string, itemId: string, newListId: string) => Promise<boolean>;
    deleteUser: (userId: string) => Promise<boolean>;
};

export type UserInfoActions = {
    updateListData: (listDataItems: ListData[]) => boolean;
};

export type UserActions = {
    onLogout: () => void;
};
interface ChildProps {
    listId?: string;
}


const myAuth: Auth = auth;

function App() {
    const [isAuth, setIsAuth] = useState<boolean | string>(localStorage.getItem("setIsAuth"));
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const classes = useStyles();

    const signInWithGoogle = async () => {
        // localStorage.setItem("setIsAuth", true);
        console.log("sign in with google");
        const result: UserCredential = await signInWithPopup(myAuth, provider);
        // .then((result) => {
        console.log("logged in ");
        //after we have the credential - lets check if the user exists in firestore
        const { user } = result;
        const docRef: DocumentReference = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        let details: UserInfoDbObject = null;
        if (!docSnap.exists()) {
            //user doesn't exist - create a new user in firestore
            details = await addNewUserToFirestore(user);
        } else {
            details = docSnap.data() as UserInfoDbObject;
        }
        console.log(details, "details");
        const userInfo = { ...details, uid: user.uid, listsDetails: [] };
        setUserInfo(userInfo);
        localStorage.setItem("setIsAuth", 'true');
        setIsAuth(true);
    };

    async function fetchUserInfo(uid) {
        // const {user} = result;
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        const details = docSnap.data() as UserInfoDbObject;
        console.log(details, "details");
        setUserInfo({ ...details, uid: uid, listsDetails: [] });
        localStorage.setItem("setIsAuth", 'true');
        setIsAuth(true);
    }

    /**
    *
    *  1. create a new list for the user
    *  2. add the list to the user's list
    *  3. add the user to the users collection
    *
    */
    async function addNewUserToFirestore(user: User): Promise<UserInfoDbObject> {
        const { metadata, uid, displayName, email, photoURL } = user;
        const { creationTime, lastSignInTime } = metadata;

        const listItem: ListDbObject = {
            name: constants.DEFAULT_LIST_NAME,
            owner: uid,
            createdT: serverTimestamp(),
            updatedT: serverTimestamp(),
        };
        const addedDoc = await addDoc(collection(db, "lists"), listItem);

        const details: UserInfoDbObject = {
            name: displayName,
            email: email,
            photoURL: photoURL,
            creationTime: creationTime,
            lastSignInTime: lastSignInTime,
            lists: [addedDoc.id],
        };
        await setDoc(doc(db, "users", user.uid), details);
        return details;
    }

    // check if list is accessible
    const dbOperations: DbOperations = {
        fetchListItems: async (listId, updateState = true) => {
            // query the items collection ordered by createdT
            const listDocRef = collection(db, "lists", listId, "items");
            const listDocSnap = await getDocs(listDocRef);
            const listItems: ItemDetails[] = [];
            listDocSnap.forEach((listItem) => {
                listItems.push({ [listItem.id]: listItem.data() as ItemObject });
            });
            // get name from the lists
            const listRef = doc(db, "lists", listId);
            const listSnap = await getDoc(listRef) as DocumentSnapshot<ListDbObject>;
            const { name, createdT, updatedT } = listSnap.data();
            const listData: ListData = { id: listId, items: listItems, createdT, updatedT, name };
            // check if listsData exists within userInfo and if it does, add the new listData to it
            if (updateState) {
                if (userInfo.listsData) {
                    // if there is an existing listData for the list Id, replace it
                    const existingListDataIndex = userInfo.listsData.findIndex((listData) => listData.id === listId);
                    if (existingListDataIndex !== -1) {
                        const newListData = [...userInfo.listsData];
                        newListData[existingListDataIndex] = listData;
                        setUserInfo({ ...userInfo, listsData: newListData });
                        return listData;
                    }
                    // add the new listData to the existing listsData
                    const newListData = [...userInfo.listsData, listData];
                    setUserInfo({ ...userInfo, listsData: newListData });
                } else {
                    setUserInfo({ ...userInfo, listsData: [listData] });
                }
            }
            return listData;
        },
        toggleItemIsDone: async (listId, itemId, isDone = true) => {
            const listItemDocRef = doc(db, "lists", listId, "items", itemId);
            await updateDoc(listItemDocRef, {
                isDone,
                updatedT: serverTimestamp(),
            });
            dbOperations.fetchListItem(listId, itemId);
        },
        updateItemDetailsInList: async (listId, itemId, itemDetails) => {
            try {
                const listItemDocRef = doc(db, "lists", listId, "items", itemId);
                await updateDoc(listItemDocRef, {
                    ...itemDetails,
                    updatedT: serverTimestamp(),
                });
                dbOperations.fetchListItem(listId, itemId);
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        fetchListItem: async (listId, itemId, updateState = true) => {
            const listItemRef = doc(db, "lists", listId, "items", itemId);
            const listItemSnap = await getDoc(listItemRef);
            if (listItemSnap.exists()) {
                const listItem = listItemSnap.data();
                if (updateState) {
                    updateItemInfo(listId, itemId, listItem);
                }
                return listItem;
            } else {
                console.log("no such document");
                return null;
            }
        },
        fetchPublicListItems: async (listId) => {
            try {
                const listDocRef = collection(db, "lists", listId, "items");
                const listDocSnap = await getDocs(listDocRef);
                const listItems = [];
                listDocSnap.forEach((listItem) => {
                    const listItemData = listItem.data() as ItemObject;
                    listItems.push({ [listItem.id]: listItemData });
                });
                return [listItems, true];
            } catch (error) {
                console.log(error);
                return [[], false];
            }
        },
        //adding an operation to fetch the ListOwner
        fetchListDetailsById: async (listId, updateUserInfoFlag) => {
            try {
                const listMetaDataRef = doc(db, "lists", listId);
                const listOwnerSnap = await getDoc(listMetaDataRef) as DocumentSnapshot<ListDbObject>;
                if (listOwnerSnap.exists()) {
                    const listDbObject = listOwnerSnap.data() as ListDbObject;
                    const listDetail = { ...listDbObject, id: listId } as ListDetails;
                    if (updateUserInfoFlag) {
                        const listDetailIndex = userInfo.listsDetails?.findIndex((listsDetail) => listsDetail.id === listId);
                        let newListDetails: ListDetails[] = [];
                        if (listDetailIndex !== -1) {
                            newListDetails = [...userInfo.listsDetails];
                            newListDetails[listDetailIndex] = listDetail;
                        } else {
                            newListDetails = [...userInfo.listsDetails, listDetail];
                        }
                        setUserInfo({ ...userInfo, listsDetails: newListDetails });
                    }
                    return listDetail;
                } else {
                    return null;
                }
            } catch (error) {
                console.log(error);
                return null;
            }
        },
        /**
         * 
         * @param listId 
         * @param itemId 
         * @returns 
         * true: if item is deleted
         * false: if item is not deleted
         * 
         */
        deleteItemFromList: async (listId, itemId) => {
            try {
                const listItemRef = doc(db, "lists", listId, "items", itemId);
                await deleteDoc(listItemRef);
                return true;
            } catch (error) {
                return false
            }
        },
        //adding a operation to fetch the ListOwner
        fetchUserName: async (userId) => {
            const UserIDRef = doc(db, "users", userId);
            const UserIDSnap = await getDoc(UserIDRef);
            if (UserIDSnap.exists()) {
                const userName = UserIDSnap.data().name;
                return userName;
            } else {
                return null;
            }
        },
        createList: async (listName) => {
            try {
                // create list in the lists collection
                const newList: ListDbObject = {
                    owner: userInfo.uid,
                    name: listName,
                    createdT: serverTimestamp(),
                    updatedT: serverTimestamp(),
                };
                const addedDoc = await addDoc(collection(db, "lists"), newList);
                // update the lists array in the user document
                const userDocRef = doc(db, "users", userInfo.uid);
                await updateDoc(userDocRef, {
                    lists: arrayUnion(addedDoc.id),
                });
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        fetchUserInfo: async (userId, updateUserInfoFlag) => {
            try {
                const userDocRef = doc(db, "users", userId);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userInfoDbObject = userDocSnap.data() as UserInfoDbObject;
                    const userInfo = {
                        ...userInfoDbObject, uid: userId, listsData: [], listsDetails: [],
                    };
                    if (updateUserInfoFlag) {
                        setUserInfo(userInfo);
                    }
                    return userInfo;
                } else {
                    return null;
                }
            } catch (error) {
                console.log(error);
                return null;
            }
        },
        updateListName: async (listId, listName) => {
            try {
                const listDocRef = doc(db, "lists", listId);
                await updateDoc(listDocRef, {
                    name: listName,
                    updatedT: serverTimestamp(),
                });
                return true;
            } catch (error) {
                return false;
            }
        },
        /**
         * @param listId
         * @returns true if the item is deleted successfully, false otherwise
         * @description removes the list doc from the lists collection in firebase and 
         * then removes the list id from the lists array in the user document in firebase
         * and then removes the list from the userInfo state
         **/
        deleteList: async (listId, updateState = true) => {
            try {
                const listDocRef = doc(db, "lists", listId);
                await deleteDoc(listDocRef);
                // update the lists array in the user document
                const userDocRef = doc(db, "users", userInfo.uid);
                await updateDoc(userDocRef, {
                    lists: arrayRemove(listId),
                });
                // remove the list from the userInfo state
                if (updateState) {
                    const userInfoClone = JSON.parse(JSON.stringify(userInfo));
                    userInfoClone.listsData = userInfoClone.listsData.filter((list) => list.id !== listId);
                    setUserInfo(userInfoClone);
                }
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        addItemToList: async (listId, itemObject) => {
            try {
                itemObject["createdT"] = serverTimestamp();
                itemObject["updatedT"] = serverTimestamp();
                await addDoc(collection(db, "lists", listId, "items"), itemObject);
                return true
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        moveItemToList: async (listId, itemId, newListId) => {
            try {
                // Check if the current listId and newListId are the same
                if (listId === newListId) {
                    console.log("The current list and the new list are the same.");
                    return true;
                }

                // Fetch the item from the current list
                const itemObject = await dbOperations.fetchListItem(listId, itemId, false) as ItemObject;

                if (!itemObject) {
                    console.log("Item not found");
                    return false;
                }

                // Delete the item from the current list
                const isDeleteSuccess = await dbOperations.deleteItemFromList(listId, itemId);

                if (!isDeleteSuccess) {
                    console.log("Item not deleted");
                    return false;
                }
                // Add the item to the new list
                const isAddItemSuccessful = await dbOperations.addItemToList(newListId, itemObject);

                return isAddItemSuccessful;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        deleteUser: async (userId) => {
            try {
                // Fetch user document
                const userDocRef = doc(db, "users", userId);
                
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    
                    const userInfoDbObject = userDocSnap.data() as UserInfoDbObject;

                    // Delete all lists associated with the user
                    for (const listId of userInfoDbObject.lists) {
                        const listDocRef = doc(db, "lists", listId);
                        await deleteDoc(listDocRef);
                    }

                    // Delete user document
                    await deleteDoc(userDocRef);

                    return true;
                } else {
                    console.log("User not found");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        }
    };

    const updateItemInfo = (listId: string, itemId: string, data: Object) => {
        if (userInfo.listsData) {
            const userInfoClone = JSON.parse(JSON.stringify(userInfo)) as UserInfo;
            // find the listId in the userInfo state and update the item info
            const listData = userInfoClone.listsData.find((list) => list.id === listId)
            if (listData) {
                const listItem = listData.items.find((item) => Object.keys(item)[0] === itemId);
                if (listItem) {
                    Object.assign(listItem[itemId], data);
                    console.log("setting new item to state", data)
                    setUserInfo(userInfoClone);
                }
            }
        }
    };


    const userActions: UserActions = {
        onLogout: () => {
            signOut(myAuth).then(() => {
                localStorage.clear();
                setIsAuth(false);
                setUserInfo(null);
                window.location.href = "/login";
            });
        },
    };

    const userInfoActions: UserInfoActions = {
        updateListData: (listDataItems) => {
            // Convert existing listsData and listDataItems into Maps
            const existingDataMap = new Map(userInfo.listsData.map(item => [item.id, item]));
            const newDataMap = new Map(listDataItems.map(item => [item.id, item]));

            // Merge Maps. If an item with the same id exists, it will be replaced by the new item
            const mergedData = new Map([...existingDataMap, ...newDataMap]);

            // Convert the merged Map back into an array
            const mergedArray = Array.from(mergedData.values());

            // Update the state
            setUserInfo({ ...userInfo, listsData: mergedArray });

            return true;
        },
    };

    /**
     *
     * Route Gating - This gates the routes that are only for logged in user
     *
     */
    const RequireAuth: React.FC<RequireAuthProps> = ({ isAuth, children }) => {
        const location = useLocation();
        const { listId } = useParams();
        return isAuth ? <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child as ReactElement<ChildProps>, { listId });
            })}
        </div> : <Navigate to="/login" state={{ from: location }} />;
    };

    const PublicRoute = ({ isAuth, children }) => {
        return isAuth ? <Navigate to="/" /> : children;
    };

    // reroute to the users list if authenticated
    const ReRouteToList = () => {
        const listId = isAuth ? userInfo.lists[0] : null;
        return isAuth ? (
            <Navigate to={`/list/${listId}`} />
        ) : (
            <Navigate to="/login" />
        );
    };

    /**
     * 
     * @returns Either the UserList Component or the Component that renders List of some other user
     * depending whether the list is user's list or not, in case the user is logged in
     */
    const RouteToPublicOrPrivateList = () => {
        const { listId } = useParams();
        if (!isAuth || !userInfo.lists.includes(listId)) {
            return <UserListPublic listId={listId} />;
        }
        return <UserList listId={listId} />;
    };


    // if user is already logged in and lands on a different page this event triggers

    useEffect(() => {
        const Unsubscribe = myAuth.onAuthStateChanged((user: User) => {
            // check if userInfo is not null and user is not null
            if (userInfo == null && user !== null) {
                fetchUserInfo(user.uid);
            }
            Unsubscribe();
        });
        // cleanup function
        return () => {
            Unsubscribe();
        };
    }, []);

    return (
        <>
            <AppContext.Provider
                value={{ dbOperations, userInfo, userActions, fetchUrlDetails, userInfoActions }}
            >
                {!isAuth || (isAuth && !_.isEmpty(userInfo)) ? (
                    <>
                        <CssBaseline />
                        < Router >
                            <Routes>
                                <Route
                                    path="/login"
                                    element={
                                        <PublicRoute isAuth={isAuth} >
                                            <Login signInWithGoogle={signInWithGoogle} />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/save"
                                    element={
                                        <RequireAuth isAuth={isAuth} >
                                            <AddProduct />
                                        </RequireAuth>
                                    }
                                />

                                < Route
                                    path="/"
                                    element={
                                        < RequireAuth isAuth={isAuth} >
                                            < DashboardNew />
                                        </RequireAuth>
                                    }
                                />
                                < Route
                                    path="/list/:listId"
                                    element={
                                        < RouteToPublicOrPrivateList />
                                    }
                                />

                                < Route
                                    path="/list"
                                    element={
                                        <ReRouteToList />
                                    }
                                />

                                <Route
                                    path="/delete-account"
                                    element={
                                        <RequireAuth isAuth={isAuth} >
                                            <DeleteAccount />
                                        </RequireAuth>
                                    }
                                />

                                < Route
                                    path="/test"
                                    element={
                                        <>
                                            {/* TODO: update list id  */}
                                            <UserListPublic
                                                listId="llKNSywYXJPBZsdlvu5K"
                                            />
                                        </>
                                    }
                                />
                            </Routes>
                            {/* <Route path="*" component={NotFound} /> */}
                        </Router>
                    </>
                ) : (
                    <Loading />
                )}
            </AppContext.Provider>
        </>
    );
}

export default App;

import { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'

import menuIcon from '../resources/menuIcon.png'
import menuIconWhite from '../resources/menuIconWhiteBackground.png'
import React from 'react'
import { DropdownMenuOption } from '../Services/helpers'

const useStyles = createUseStyles({
    menu: {
        position: 'relative',
        cursor: 'pointer',
        width:'28px',
        height:'28px',
    },
    dropdown: {
        position: 'absolute',
        top: '120%',
        right: 40,
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: 9999,
        padding: '0 8px',
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f0f0f0'
        },
        '&:first-child': {
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'
        },
        '&:last-child': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px'
        },
        '& + &': {
            borderTop: '1px solid #F1F1F1'
        }
    },
    icon: {
        marginRight: '8px'
    }
})

export interface DropdownMenuProps {
    dropdownOptions: DropdownMenuOption[],
    variant?: DropdownMenuVariants,
    onOptionClick: (optionLabel: string) => void
}

export enum DropdownMenuVariants {
    WHITE = 'WHITE',
    TRANSPARENT = 'TRANSPARENT'
}


const DropdownMenu: React.FC<DropdownMenuProps> = ({ dropdownOptions, onOptionClick, variant }) => {
    const [isOpen, setIsOpen] = useState(false)
    const classes = useStyles()

    function handleToggle() {
        setIsOpen(!isOpen)
    }

    function handleOptionClick(label: string): void {
        setIsOpen(false)
        onOptionClick(label)
    }

    const menuIconImage = variant === DropdownMenuVariants.WHITE ? menuIconWhite : menuIcon


    return (
        // <div className={classes.menu}>
        <>
            <img src={menuIconImage} onClick={handleToggle} className={classes.menu} />
            {
                // if menu is open, render the dropdown using the dropdownOptions

                <div className={classes.dropdown} >
                    {
                        isOpen &&
                        dropdownOptions.map((option) => {
                            return (
                                <div key={option.label} id={option.label} className={classes.option} onClick={() => handleOptionClick(option.label)}>
                                    <img src={option.icon} className={classes.icon} />
                                    {option.label}
                                </div>
                            )
                        })
                    }
                </div>

            }
        </>
    )
}

export default DropdownMenu;

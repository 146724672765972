import { createUseStyles } from 'react-jss';
import Text from './Text';
import { useContext } from 'react';
import { AppContext } from '../context';
import React from 'react';
import { constants } from '../Services/constants';



const useStyles = createUseStyles({
  container: {
    background: '#FFFFFF',
    display: 'flex',
    boxShadow: '0px 10px 40px rgba(48, 49, 52, 0.12)',
    borderRadius: '16px',
    padding: '20px 20px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (max-width: 794px)': {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    }
  },
  textSection: {
    marginBottom: '20px',
  },
  topNavButton1: {
    //background: 'linear-gradient(97.51deg, rgba(190, 6, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
    background:'black',
    boxShadow: '2px 2px 9px rgba(0, 0, 0, 0.15)',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '700',
    textAlign: 'center',
    letterSpacing: '0.05em',
    color: '#FFFFFF',
    fontSize: '20px',
    minWidth: '153px',
    padding: '15px 30px',
    borderRadius: '14px',
    cursor: 'pointer',
    position: 'relative',
    top: '25%',
    '@media (max-width: 794px)': {
      fontSize: '14px',
      padding: '10px 20px',
      minWidth: '100px',
      borderRadius: '10px',
    }
  },
})

interface TopNavCreateWishlistProps {
  handleClick: (buttonId: string, componentName: string) => void;
  title: string;
  description: string;
}

const componentName = constants.ANALYTICS_IDS.COMPONENTS.BANNER_WITH_BUTTON.COMPONENT_NAME;

const addBtnId = constants.ANALYTICS_IDS.COMPONENTS.BANNER_WITH_BUTTON.ADD_BUTTON_ID;

const BannerWithButton: React.FC<TopNavCreateWishlistProps> = ({ title, description, handleClick }) => {
  const classes = useStyles()
  const { userInfo } = useContext(AppContext);
  return (
    <>
      <div className={classes.container}>
        <div className={classes.textSection}>
          <Text fontSize={24} fontWeight={700}>{title}</Text>
          <Text fontSize={18} fontColor={"#848484"}>{description}</Text>
        </div>
        <div>
          <button
            className={classes.topNavButton1}
            onClick={() => handleClick(addBtnId, componentName)}
            id={addBtnId}
          >
            Add New
          </button>
        </div>
      </div>
    </>
  );
}
export default BannerWithButton;
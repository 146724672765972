// create react component that has a card and renders all the items in the list as flex wrap
import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

// local imports
import Layout from '../../components/Layout';
import Container from './Container';
import CardsContainer from '../../components/CardsContainer';
import CardWithImages, { CardWithImageVariants } from '../../components/CardWithImages';


// helpers
import { AppContext } from '../../context';
import { ItemDetails, ListData } from '../../App';



const useStyles = createUseStyles({
    button: {
        boxShadow: '2px 2px 9px rgba(0, 0, 0, 0.15)',
        borderRadius: '14px',
        padding: '15px 30px',
        border: 'none',
        fontWeight: 'bold',
        fontSize: '20px',
        cursor: 'pointer',
        background: 'linear-gradient(97.51deg, rgba(6, 210, 255, 0.88) 0%, rgba(6, 210, 255, 0.88) 104.62%)',
        marginTop: '20px',
        width: 'fit-content',
    }
});

interface UserListPublicProps {
    listId: string;
}

interface ListOwnerDetails {
    photoURL: string;
    name: string;
}

const UserListPublic: React.FC<UserListPublicProps> = ({ listId }) => {
    const { dbOperations } = useContext(AppContext);
    const [listData, setListData] = useState<ItemDetails[]>([]);
    const [listTitle, setListTitle] = useState<string>("");
    const [listOwnerDetails, setListOwnerDetails] = useState<ListOwnerDetails>({ photoURL: "", name: "" });

    const classes = useStyles();
    const navigate = useNavigate();
    // fetch list items
    useEffect(() => {
        // fetch list items
        const fetchItems = async () => {
            const [listItems, isFetchSuccessful] = await dbOperations.fetchPublicListItems(listId);
            if (isFetchSuccessful) {
                setListData(listItems);
            } else {
                console.log("error fetching list items")
            }
        }
        fetchItems();
    }, [listId, dbOperations]);

    // fetch list details
    useEffect(() => {
        const fetchListDetails = async () => {
            const listDetails = await dbOperations.fetchListDetailsById(listId, false);
            if (listDetails) {
                setListTitle(listDetails.name);
                const ownerInfo = await dbOperations.fetchUserInfo(listDetails.owner, false);
                if (ownerInfo) {
                    const ownerDetails: ListOwnerDetails = {
                        photoURL: ownerInfo.photoURL,
                        name: ownerInfo.name,
                        
                    }
                    setListOwnerDetails(ownerDetails);
                } else {
                    console.log("error fetching owner details")
                }
            }
            else {
                console.log("error fetching list details")
            }
        }
        fetchListDetails();
    }, [listId, dbOperations]);

    const handleClickGoToTopick = () => {
        // navigate to the dashboard using react router
        navigate("/");
    }


    return (
        <Layout maxWidth='927px' >
            {/* align items in the center */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} >
                <Container
                    name={listOwnerDetails.name}
                    image={listOwnerDetails.photoURL}
                    numberOfItems={listData.length}
                    title={listTitle}
                >
                    <CardsContainer>
                        {
                            listData?.map((item) => {
                                // get the first key of the item object
                                const id = Object.keys(item)[0];
                                // const { img, title, description, id, isAcquired } = item;
                                return (<CardWithImages
                                    key={id}
                                    id={id}
                                    variant={CardWithImageVariants.LIST_ITEM_PUBLIC}
                                    listItemDetails={item}
                                />)
                            })}
                    </CardsContainer>
                </Container>
                <button
                    className={classes.button}
                    onClick={handleClickGoToTopick}
                >
                    Go to Topick
                </button>
            </div>
        </Layout>
    );
};

export default UserListPublic;

import { HttpsCallableResult } from 'firebase/functions';
import React, { createContext } from 'react';
import { DbOperations, UserActions, UserInfo, UserInfoActions } from './App';

type AppContextType = {
    dbOperations: DbOperations;
    userInfo: UserInfo;
    userActions: UserActions;
    userInfoActions: UserInfoActions;
    fetchUrlDetails: (url: string) => Promise<HttpsCallableResult<unknown>>;
};

export const AppContext = React.createContext<AppContextType>(null);

// dependencies
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../context';

// external resources
import Logout from '@mui/icons-material/Logout'

// resources
import logo from '../resources/logo.png';

// TODO: get rid of this component and use the NavbarNew component instead

const useStyles = createUseStyles({
  navBar: {
    height: '105px',
    background: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    maxWidth: '1200px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

function Navbar() {

  const classes = useStyles()
  const navigate = useNavigate();
  const { userActions } = useContext(AppContext);

  return (
    <div className={classes.navBar}>
      <div className={classes.container}>
        <img src={logo} alt="logo of the app" onClick={() => navigate("/")} style={{ cursor: 'pointer', marginLeft: '-11px' }} />
        <Logout
          sx={{ color: 'white', cursor: 'pointer', fontSize: 40 }}
          onClick={() => userActions.onLogout()}
        />
      </div>
    </div>
  );
}

export default Navbar;
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import React from "react";


export const FormInputSelect = ({ name, control, label, defaultValue, disabled = false, sx, required = false, options }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ fieldState, field: { onChange, value, ref } }) => (
                <>
                    <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        style={{ fontWeight: "bold", color: "black", marginTop: '20px' }}
                        sx={{ mt: 4 }}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        fullWidth
                        disabled={disabled}
                        sx={sx}
                        error={!!fieldState.error}
                        size='small'
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )}
        />
    );
}

export default FormInputSelect;

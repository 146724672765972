// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Inter, sans-serif',
        customSubHeading: {
            fontWeight: 700,
            fontSize: '24px',
        },
    },
});

export default theme;

export const constants = {
    CHROME_EXTENSION_URL: 'https://chrome.google.com/webstore/detail/topick-pick-an-item/ndpgcajnnddjnedbgmegebaadkafkihf',
    DEFAULT_LIST_NAME: 'default list',
    // ids for analytics
    ANALYTICS_IDS: {
        COMPONENTS: {
            BANNER_WITH_BUTTON: {
                COMPONENT_NAME: 'banner-with-button',
                ADD_BUTTON_ID: 'banner-add-button',
            },
            BANNER_CHROME_EXTENSION: {
                DOWNLOAD_BUTTON_ID: 'banner-chrome-extension-download-button',
                COMPONENT_NAME: 'banner-chrome-extension',
            },
            NAVBAR: {
                COMPONENT_NAME: 'navbar',
            },
            ADD_NEW_CARD: {
                COMPONENT_NAME: 'add-new-card',
                ADD_BUTTON_ID: 'add-new-card-add-button',
            },
            SHARE_BUTTON: {
                COMPONENT_NAME: 'share-button',
                SHARE_BUTTON_ID: 'share-button-item-page',
            },
            GO_TO_STORE: {
                COMPONENT_NAME: 'go-to-store',
                GO_TO_STORE_BUTTON: 'go-to-store-button',
            },
            ACQUIRED: {
                COMPONENT_NAME: 'acquired',
                BUTTON: 'acquired-button',
            },
        },
        PAGES: {
            DASHBOARD: {
                PAGE_NAME: 'dashboard',
                PAGE_LOCATION: '/',
            },
            USER_LIST: {
                PAGE_NAME: 'user-list',
                PAGE_LOCATION: '/list/:listId',
            },
            SAVE_ITEM: {
                PAGE_NAME: 'save-item',
                PAGE_LOCATION: '/save',
            },
        },
    }
}
// material ui
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";

//style
import {createUseStyles} from 'react-jss';

// internal
import { auth, provider } from "../firebaseConfig";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import SignUp from "../components/SignUp";
import SignUp_new from "../components/SignUp_new";


const useStyles = createUseStyles({
  container: {
    maxWidth: '1280px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center'
  }
})

export default function Login({ signInWithGoogle }) {
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles()

  const handleLogin = async () => {
    await signInWithGoogle();
    const from = location.state?.from || '/';
    navigate(from);
};

  return (
    <>
    {/* <Navbar /> */}
    <div className={classes.container}>
      <SignUp_new handleLogin={handleLogin} />
    </div>
    </>
  );
}

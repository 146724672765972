// dependencies
import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';


// local
import NavbarNew from '../../components/NavbarNew';
import Layout from "../../components/Layout";
import TwoColumnLayout from '../../components/TwoColumnLayout';
import BannerWithButton from '../../components/BannerWithButton';
import AddNewCard from '../../components/AddNewCard';
import CardsContainer from '../../components/CardsContainer';
import CardWithImages, { CardWithImageVariants } from '../../components/CardWithImages';

// helpers and constants
import { AnalyticsEventNames, AnalyticsEventParams, dropdownOptionsWithMove, logAnalyticsEvent } from '../../Services/helpers'
import { constants } from '../../Services/constants';
import { AppContext } from '../../context';
import { ItemObject, ListData } from '../../App';
import CustomModal from '../../components/CustomModal';
import { Form } from '../DashboardNew';
import shareArrow from '../../resources/shareArrow.png';

import { Snackbar } from "@mui/material";
import { useNavigate } from 'react-router-dom';




const pageName = constants.ANALYTICS_IDS.PAGES.USER_LIST.PAGE_NAME;
const pageLocation = constants.ANALYTICS_IDS.PAGES.USER_LIST.PAGE_LOCATION;

const logButtonClickEvent = (buttonId: string, componentName: string) => {
    const eventParam: AnalyticsEventParams = {
        buttonId,
        componentName,
        pageName,
        pageLocation,
    };
    logAnalyticsEvent(AnalyticsEventNames.BUTTON_CLICK, eventParam);
}

interface UserListProps {
    listId: string;
}

const dropdownOptions = dropdownOptionsWithMove;

const useStyles = createUseStyles({
    shareButton: {
        boxShadow: '2px 2px 9px rgba(0, 0, 0, 0.15)',
        borderRadius: '14px',
        padding: '15px 30px 15px 60px',
        border: 'none',
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'white',
        background: `url(${shareArrow}) no-repeat left 30px center, black`,
        cursor: 'pointer',
        '@media (max-width: 794px)': {
            width: '100px',
            height: '35px',
            background: `url(${shareArrow}) no-repeat left 10px center, black`,
            fontWeight: 'bold',
            fontSize: '14px',
            borderRadius: '10px',
            padding: '0px 20px 0px 40px',
        }
    }
});

const UserList: React.FC<UserListProps> = ({ listId }) => {
    const { userInfo, dbOperations, userInfoActions } = useContext(AppContext);
    const [listData, setListData] = React.useState<ListData>();
    const [currentItemId, setCurrentItemId] = React.useState<string>('');
    const [isMoveItemModalOpen, setIsMoveItemModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    // check if listId is in userInfo.listsData and if not, fetch it from db. If it is, setListData to it.
    React.useEffect(() => {
        if (userInfo && dbOperations) {
            //If the listsData property does not exist, the expression will evaluate to undefined and the find method will not be called.
            const listData = userInfo.listsData?.find((list) => list.id === listId);
            if (listData) {
                console.log("updating local state with listData: ", listData)
                setListData(listData);
            } else {
                dbOperations.fetchListItems(listId)
                    .catch((err) => {
                        console.log("Error fetching list items: ", err);
                    })
            }
        }
    }, [userInfo, dbOperations, listId, userInfo?.listsData]);



    const handleClickAddNewItem = (buttonId: string, componentName: string) => {
        logButtonClickEvent(buttonId, componentName);
        navigate(`/save?listId=${listId}`);
    }

    const handleSubmitDeleteList = async () => {
        const itemId = currentItemId;
        // close the modal
        closeModal();
        // delete the item from the list
        if (dbOperations) {
            const isItemDeletionSuccessful = await dbOperations.deleteItemFromList(listId, itemId)
            if (isItemDeletionSuccessful) {
                // fetch the updated list data
                dbOperations.fetchListItems(listId);
            }
            else {
                console.log("Error deleting item from list");
            }
        } else {
            console.log("dbOperations not available");
        }
    }

    const handleSubmitMoveItemToList = async (newlistId: string) => {
        console.log("newlistId: ", newlistId);
        console.log("currentItemId: ", currentItemId);
        console.log("listId: ", listId);
        const itemId = currentItemId;
        // close the modal
        closeModal();
        // move the item to the new list
        if (dbOperations) {
            const isItemMoveSuccessful = await dbOperations.moveItemToList(listId, itemId, newlistId);
            if (isItemMoveSuccessful) {
                // fetch the updated list data
                const currentListData = await dbOperations.fetchListItems(listId)
                const targetListData = await dbOperations.fetchListItems(newlistId);
                // update the list data in the context
                userInfoActions?.updateListData([currentListData, targetListData]);
            }
            else {
                console.log("Error moving item to list");
            }
        } else {
            console.log("dbOperations not available");
        }
    }

    const closeModal = () => {
        setIsMoveItemModalOpen(false);
        setIsDeleteModalOpen(false);
    }

    const handleDropdownMenuOptionClick = (label: string, itemId: string, itemObject: ItemObject) => {
        setCurrentItemId(itemId);
        if (label === 'Edit') {
            const { title, description, img, url, price, currency } = itemObject;
            navigate(`/save?listId=${listId}&itemId=${itemId}&title=${title}&description=${description}&img=${img}&url=${url}&price=${price}&currency=${currency}`);

        } else if (label === 'Delete') {
            // launch modal with delete option
            setIsDeleteModalOpen(true);
        } else if (label === 'Move') {
            // launch modal with list of lists
            setIsMoveItemModalOpen(true);
        }
    }

    const handleToggleItemIsDone = (itemId: string, isDone: boolean) => {
        if (dbOperations) {
            dbOperations.toggleItemIsDone(listId, itemId, isDone);
        }
    }

    const handleShareClick = () => {
        // copy the current url to the clipboard
        navigator.clipboard.writeText(window.location.href);
        // show snackbar
        setIsSnackbarOpen(true);
    }

    return (<>
        <Layout>
            <TwoColumnLayout>
                <NavbarNew logEvent={logButtonClickEvent} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <BannerWithButton
                            title={`Add to your list.`}
                            description="Click the 'Add New' button or use our chrome extension."
                            handleClick={handleClickAddNewItem} />
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        {/* add wrapper so that the child elements are at both the end of the row */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '32px 0 16px' }}>
                            <Typography
                                component="h2"
                                // sx={{ marginBottom: 2, marginTop: 4 }}
                                style={{
                                    fontWeight: 700,
                                    fontSize: '24px',
                                }}
                            >
                                {listData?.name}
                            </Typography>
                            <button
                                className={classes.shareButton}
                                onClick={handleShareClick}
                            >
                                Share
                            </button>
                        </div>

                        <CardsContainer>
                            
                            {
                                listData?.items.map((item) => {
                                    // get the first key of the item object
                                    const id = Object.keys(item)[0];
                                    // const { img, title, description, id, isAcquired } = item;
                                    return (<CardWithImages
                                        key={id}
                                        id={id}
                                        variant={CardWithImageVariants.LIST_ITEM}
                                        dropdownOptions={dropdownOptions}
                                        onOptionClick={(label) => {
                                            const itemId = id;
                                            const itemDetails = item[itemId];
                                            handleDropdownMenuOptionClick(label, itemId, itemDetails);
                                        }}
                                        listItemDetails={item}
                                        handleToggleIsDone={(isDone) => {
                                            handleToggleItemIsDone(id, isDone);
                                        }}
                                    />)
                                })}
                            <AddNewCard onClick={handleClickAddNewItem} />
                        </CardsContainer>
                    </div>
                </div>
            </TwoColumnLayout>
        </Layout>

        {/* Delete Modal */}
        <CustomModal
            header='Are you sure you want to delete this item?'
            isOpen={isDeleteModalOpen}
            onClose={closeModal}
        >
            <Form
                onSubmit={handleSubmitDeleteList}
                submitButtonText='Delete'
                showTextArea={false}
            />
        </CustomModal>
        {/* Move Item Modal */}
        <CustomModal
            header='Select the list you want to move this item to.'
            isOpen={isMoveItemModalOpen}
            onClose={closeModal}
        >
            <Form
                onSubmit={handleSubmitMoveItemToList}
                submitButtonText='Move'
                showTextArea={false}
                dropdownItems={userInfo?.listsData?.map((list) => ({ label: list.name, value: list.id }))}
            />
        </CustomModal>
        
        <Snackbar
            open={isSnackbarOpen}
            onClose={() => setIsSnackbarOpen(false)}
            autoHideDuration={2000}
            message="Link Copied to clipboard"
        />
    </>
    )
}

export default UserList;
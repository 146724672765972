import React from 'react';
import { createUseStyles } from 'react-jss';

interface StyleProps {
    backgroundColor?: string;
    height?: string;
    maxHeight?: string;
    minHeight?: string;
}


const useStyles = createUseStyles({
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        // height: '100%',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        borderRadius: '20px',
        backgroundColor: (props: StyleProps) => props.backgroundColor || '#fff',
        
        height: (props: StyleProps) => props.height || 'auto',
        maxHeight: (props: StyleProps) => props.maxHeight || 'none',
        minHeight: (props: StyleProps) => props.minHeight || 'none',
        flexGrow: 1,
        rowGap: '35px',
        columnGap: '16px',
        // apply only to mobile
        '@media (max-width: 794px)': {
            justifyContent: 'center',
            padding: '30px 20px',
        },
        // apply only to desktop
        '@media (min-width: 794px)': {
            padding: '40px 12px 40px 38px'
        },
    },
})

interface CardsContainerProps extends StyleProps {
    children: React.ReactNode;
}

const CardsContainer: React.FC<CardsContainerProps> = ({ backgroundColor, height, maxHeight, minHeight, children }) => {
    const classes = useStyles({ backgroundColor, height, maxHeight, minHeight })
    return (
        // make the container a flexbox
        <div className={classes.outerContainer}>
            <div className={classes.container}>
                {children}
            </div>
        </div>
    )
}

export default CardsContainer

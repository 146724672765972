import React, { useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { AppContext } from '../../context';



const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // This assumes you want to center the button vertically in the whole viewport
    },
    button: {
        margin: '10px',
    },
    deleteButton: {
        backgroundColor: 'red',
        color: 'white',
        '&:hover': {
            backgroundColor: 'darkred',
        },
    },
    cancelButton: {
        backgroundColor: 'grey',
        color: 'white',
        '&:hover': {
            backgroundColor: 'darkgrey',
        },
    },
});


const DeleteAccount = () => {
    const [showConfirm, setShowConfirm] = useState(false);
    const { userInfo, dbOperations, userActions } = useContext(AppContext);
    const classes = useStyles();


    const deleteAccount = async () => {
        if (userInfo && dbOperations) {
            const isDeleteSuccess = await dbOperations.deleteUser(userInfo.uid);
            if (isDeleteSuccess) {
                userActions.onLogout();
            }
            else {
                alert("There was an error deleting the account. Please contact support.")
            }
        }

    };

    return (
        <div className={classes.container}>
            <button className={`${classes.button} ${classes.deleteButton}`} onClick={() => setShowConfirm(true)}>Delete Account</button>
            {showConfirm && (
                <div>
                    <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                    <button className={`${classes.button} ${classes.deleteButton}`} onClick={deleteAccount}>Yes, delete my account</button>
                    <button className={`${classes.button} ${classes.cancelButton}`} onClick={() => setShowConfirm(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default DeleteAccount;
import { AppContext } from '../../context';
import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
    footer: {
        backgroundColor: 'white',
        padding: '25px 45px',
        borderRadius: '0 0 20px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        // disable borderRadius on mobile
        '@media (max-width: 927px)': {
            borderRadius: 0,
        },
    },
    footerTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    footerDescription: {
        fontSize: '14px',
        fontWeight: 400,
        color: 'gray',
        marginTop: '5px',
    },
    topickLink: {
        color: '#3dbcff',
        fontWeight: 'bold',
        fontSize: '20px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    loginButton: {
        backgroundColor: '#E1E3E2', // <-- update the background color here
        color: 'black',
        fontSize: '14px',
        fontWeight: 600,
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
    },
});

const Footer: React.FC = () => {
    const classes = useStyles();
    const { userInfo } = useContext(AppContext);
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/');
    };

    return (
        <div className={classes.footer}>
            <div>
                <div className={classes.footerTitle}>
                    Create Your Wish list on{' '}
                    <a
                        onClick={handleLogin}
                        className={classes.topickLink}
                    >
                        Topick
                    </a>
                </div>
                <div className={classes.footerDescription}>
                    Make list creation more easy and fun.
                </div>
            </div>
            {userInfo && userInfo.email ? (
                <></>
            ) : (
                <button className={classes.loginButton} onClick={handleLogin}>
                    Login
                </button>
            )
            }
        </div>
    );
};

export default Footer;

import React from 'react';
import { createUseStyles } from 'react-jss';
import CenteredContainer from './CenteredContainer';
import Circle from './Circle';
import { constants } from '../Services/constants';
import { Typography } from '@mui/material';



const useStyles = createUseStyles({
    card: (props: AddNewCardStyleProps) => ({
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        minHeight: props.minHeight || 'auto',
    }),
});

interface AddNewCardProps {
    onClick: (buttonId: string, componentName: string) => void;
    minHeight?: string;
    children?: React.ReactNode;
}

interface AddNewCardStyleProps {
    minHeight?: string;
}

const buttonId = constants.ANALYTICS_IDS.COMPONENTS.ADD_NEW_CARD.ADD_BUTTON_ID;
const componentName = constants.ANALYTICS_IDS.COMPONENTS.ADD_NEW_CARD.COMPONENT_NAME;

const AddNewCard: React.FC<AddNewCardProps> = ({ minHeight, onClick, children }) => {
    const classes = useStyles({ minHeight });
    const handleClick = () => {
        onClick(buttonId, componentName);
    }
    return (
        <CenteredContainer minHeight={'194px'} width={'192px'}>
            <div style={{ cursor: 'pointer' }} onClick={handleClick} >
                <Circle />
                {/* Add text using typography */}
                <Typography
                    component="p"
                    style={{ marginTop: 20 }}
                    fontWeight="bold"
                >
                    Add New
                </Typography>
            </div>
        </CenteredContainer>
    );
}

export default AddNewCard;
